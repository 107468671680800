import moment from 'moment';

const TEN_MILLION = 10_000_000;
const TEN_THOUSAND = 10_000;

function floor10(num: number): number {
  return Math.floor(num / 100) * 100;
}

function formatWithCommas(num: any): string {
  if (isNaN(num) || typeof num !== 'number') {
    return '0';
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatCurrency(num: number): string {
  if (Math.abs(num) >= TEN_MILLION * 100) {
    return formatWithCommas(Math.floor(num / TEN_MILLION)) + '천만';
  }

  if (Math.abs(num) >= TEN_THOUSAND * 100) {
    return formatWithCommas(Math.floor(num / TEN_THOUSAND)) + '만';
  }

  return formatWithCommas(num);
}

function toFixed(num: number, precision: number = 1): number | string {
  return Number.isInteger(num) ? num.toFixed(precision) : num;
}

function toPercent(rate: number, precision: number = 0): string {
  return (rate * 100).toFixed(precision);
}

function toDuration(mills: number): string {
  const duration = moment.duration(mills);

  const seconds = duration.seconds();
  const minutes = duration.minutes();

  if (minutes > 0) {
    return `${minutes}분 ${seconds % 60}초`;
  } else {
    return `${seconds}초`;
  }
}

export {
  floor10,
  formatWithCommas,
  formatCurrency,
  toFixed,
  toPercent,
  toDuration,
};
