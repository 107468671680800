import gql from 'graphql-tag.macro';

export default gql`
  mutation login($login: ID!, $password: String!) {
    login(input: { login: $login, password: $password }) {
      jwt
      errors {
        field
        messages
      }
    }
  }
`;
