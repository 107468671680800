import React, { Component } from 'react';

import Form from 'components/Form';
import { Formik } from 'formik';
import t from 'i18n';

class LoginForm extends Component {
  componentDidUpdate() {
    this.formik.setErrors(this.props.errors);
  }

  validateForm = (values) => {
    const errors = {};
    if (!values.login) {
      errors.login = t('errors.messages.required');
    }
    if (!values.password) {
      errors.password = t('errors.messages.required');
    } else if (values.password.length < 4) {
      errors.password = t('errors.messages.invalid');
    }
    return errors;
  };

  handleFormSubmit = (values, { setSubmitting }) => {
    this.props
      .onSubmit(values)
      .then(() => setSubmitting(false))
      .catch(() => setSubmitting(false));
  };

  render() {
    return (
      <Formik
        ref={(formik) => {
          this.formik = formik;
        }}
        initialValues={{
          login: '',
          password: '',
        }}
        validate={this.validateForm}
        onSubmit={this.handleFormSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group
              id="login"
              label="휴대전화번호 또는 아이디"
              error={touched.login && errors.login}
            >
              <Form.MaskedInput
                autoCapitalize="none"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.login}
              />
            </Form.Group>
            <Form.Group
              id="password"
              label="비밀번호"
              error={touched.password && errors.password}
            >
              <Form.Input
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </Form.Group>
            <Form.Submit disabled={isSubmitting} data-testid="login-button">
              로그인
            </Form.Submit>
          </Form>
        )}
      />
    );
  }
}

export default LoginForm;
