import system from '@rebass/components';

export const Position = system(
  {},
  'space',
  'width',
  'color',
  'zIndex',
  'top',
  'right',
  'bottom',
  'left'
);

Position.displayName = 'Position';

export const Relative = system(
  {
    extend: Position,
  },
  {
    position: 'relative',
  }
);
Relative.displayName = 'Relative';

export const Absolute = system(
  {
    extend: Position,
  },
  {
    position: 'absolute',
  }
);

Absolute.displayName = 'Absolute';

export const Fixed = system(
  {
    extend: Position,
  },
  {
    position: 'fixed',
  }
);
Fixed.displayName = 'Fixed';

export default Position;
