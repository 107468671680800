import { useParams } from 'react-router-dom';

export function useCurrentBusinessId(): string {
  const { businessId } = useParams();

  if (!businessId) {
    throw new Error('Cannot find businessId from the current location params.');
  }

  return businessId;
}
