import React from 'react';

import RetrieveForm from './RetrieveForm';

const Retrieve = () => (
  <section>
    <header>
      <h2>아이디 찾기</h2>
    </header>
    <RetrieveForm />
  </section>
);

export default Retrieve;
