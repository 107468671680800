import gql from 'graphql-tag.macro';

export const deliveryAppCredential = gql`
  fragment deliveryAppCredential on DeliveryAppCredential {
    id
    login
    status
  }
`;

export const cardLoginCredential = gql`
  fragment cardLoginCredential on CardLoginCredential {
    issuer
    cardNumber
    type
    status
    id
    createdAt
  }
`;

export const cardCertificateCredential = gql`
  fragment cardCertificateCredential on CardCertificateCredential {
    issuer
    status
    id
    createdAt
  }
`;

export const crefiaLoginCredential = gql`
  fragment crefiaLoginCredential on CrefiaLoginCredential {
    login
    registrationNumber
    status
    id
    createdAt
  }
`;

export const crefiaBusinessCredential = gql`
  fragment crefiaBusinessCredential on CrefiaBusinessCredential {
    registrationNumber
    status
    id
    createdAt
  }
`;

export const hometaxLoginCredential = gql`
  fragment hometaxLoginCredential on HometaxLoginCredential {
    login
    status
    id
    createdAt
    type
  }
`;

export const hometaxCertificateCredential = gql`
  fragment hometaxCertificateCredential on HometaxCertificateCredential {
    commonName
    organizationName
    status
    id
    createdAt
    expiredAt
    type
  }
`;

export const bankAccountCredential = gql`
  fragment bankAccountCredential on BankAccountCredential {
    commonName
    bankName
    type
    status
    id
    createdAt
  }
`;

export const cardFranchiseCredential = gql`
  fragment cardFranchiseCredential on CardFranchiseCredential {
    id
    status
    issuer
    login
    lastScrapedAt
  }
`;

export const kepcoCredential = gql`
  fragment kepcoCredential on KepcoCredential {
    id
    status
    number: formattedCustomerNumber
  }
`;

export default gql`
  fragment credentials on CredentialConnection {
    totalCount
    edges {
      node {
        ...cardLoginCredential
        ...cardCertificateCredential
        ...crefiaLoginCredential
        ...crefiaBusinessCredential
        ...hometaxLoginCredential
        ...hometaxCertificateCredential
        ...bankAccountCredential
        ...deliveryAppCredential
        ...cardFranchiseCredential
        ...kepcoCredential
        __typename
      }
    }
  }
  ${cardLoginCredential}
  ${cardCertificateCredential}
  ${crefiaLoginCredential}
  ${crefiaBusinessCredential}
  ${hometaxLoginCredential}
  ${hometaxCertificateCredential}
  ${bankAccountCredential}
  ${deliveryAppCredential}
  ${cardFranchiseCredential}
  ${kepcoCredential}
`;
