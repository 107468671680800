import gql from 'graphql-tag.macro';

import credentials from '../fragments/credentials.fragment';

export default gql`
  query getCredentials($businessId: ID!, $types: [CredentialType!]) {
    business(businessId: $businessId) {
      id
      credentials(types: $types) {
        ...credentials
      }
    }
  }
  ${credentials}
`;
