import moment from 'moment';
import type { Moment } from 'moment';
import { stringify } from 'query-string';

import type { ModeType, DateRange } from 'types';
import type { PeriodType } from 'libs/period';
import { MODE } from 'types';

import useSearchParams from 'hooks/useSearchParams';

import { Day, Week, Month, Range } from 'libs/period';

import paths from 'paths';

interface DateRangeQuery {
  mode: ModeType;
}

interface DayQuery extends DateRangeQuery {
  year: number;
  month: number;
  day: number;
}

interface WeekQuery extends DateRangeQuery {
  year: number;
  month: number;
  week: number;
}

interface MonthQuery extends DateRangeQuery {
  year: number;
  month: number;
}

interface CustomQuery extends DateRangeQuery {
  start: string;
  end: string;
}

export function generateDateRangeQueries({
  mode,
  startDate,
  endDate,
}: DateRange): DayQuery | WeekQuery | MonthQuery | CustomQuery {
  switch (mode) {
    case MODE.DAY:
      return {
        mode,
        year: startDate.year(),
        month: startDate.month() + 1,
        day: startDate.date(),
      };

    case MODE.WEEK:
      const thursday = moment(startDate).day(4);
      return {
        mode,
        year: thursday.year(),
        month: thursday.month() + 1,
        week: Math.ceil(thursday.date() / 7),
      };

    case MODE.MONTH:
      return {
        mode,
        year: startDate.year(),
        month: startDate.month() + 1,
      };

    case MODE.CUSTOM:
      return {
        mode,
        start: startDate.format('YYYY-MM-DD'),
        end: endDate.format('YYYY-MM-DD'),
      };
  }
}

interface UpdateDateRangeQueryProps extends DateRange {
  other?: any;
}

export function updateDateRangeQueries({
  mode,
  startDate,
  endDate,
  other = {},
}: UpdateDateRangeQueryProps) {
  paths.mergeSearchKey({
    ...generateDateRangeQueries({
      mode,
      startDate,
      endDate,
    }),
    ...other,
  });
}

function convertToPeriod({ mode, startDate, endDate }: DateRange) {
  switch (mode) {
    case MODE.DAY:
      return new Day(startDate);
    case MODE.WEEK:
      return new Week(startDate);
    case MODE.MONTH:
      return new Month(startDate);
    case MODE.CUSTOM:
      return new Range(startDate, endDate);
  }
}

interface DateRangeVariable {
  start: Moment;
  end: Moment;
}

interface DateRangeVariables {
  dateRange0: DateRangeVariable;
  dateRange1: DateRangeVariable;
  dateRange2: DateRangeVariable;
  dateRange3: DateRangeVariable;
  dateRange4: DateRangeVariable;
  dateRange5: DateRangeVariable;
  dateRange6: DateRangeVariable;
}

export function dateRangePeriodsAndVariables(
  dateRange: DateRange
): [PeriodType[], DateRangeVariables] {
  const period = convertToPeriod(dateRange);

  const periods = [period];
  let pastPeriod = period.prev();
  for (let i = 1; i < 7; i++) {
    periods.push(pastPeriod);
    pastPeriod = pastPeriod.prev();
  }

  const variables = periods.reduce((acc, period, index) => {
    acc[`dateRange${index}`] = period.toDateRangeInput();
    return acc;
  }, {} as any);

  return [periods, variables];
}

export function periodToDateRangeQuery(period: PeriodType, params = {}) {
  return `?${stringify({
    ...generateDateRangeQueries(period.toDateRange()),
    ...params,
  })}`;
}

type Param = string | string[] | null | undefined;

function parse(value: Param): number {
  if (typeof value !== 'string') {
    throw new Error('Invalid dateRange param provided');
  }

  return parseInt(value, 10);
}

function useDateRange(): DateRange {
  const { mode, year, month, week } = useSearchParams();

  const date = moment().subtract(1, 'day');
  const defaultRange = {
    mode: MODE.WEEK,
    startDate: moment(date).startOf('week'),
    endDate: moment(date).endOf('week'),
    maxDate: moment(date).endOf('week'),
  };

  if (!mode) {
    return defaultRange;
  }

  const startDate = moment({
    year: parse(year),
    month: parse(month) - 1,
  });

  startDate.day() > 4 ? startDate.day(11) : startDate.day(4);
  startDate.add(parse(week) - 1, 'weeks');
  startDate.day(1);

  return {
    // @ts-ignore
    mode,
    startDate,
    endDate: moment(startDate).endOf('isoWeek'),
    maxDate: defaultRange.maxDate,
  };
}

export default useDateRange;
