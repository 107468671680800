import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import toast from 'react-hot-toast';

import ResetForm from './ResetForm';

import resetPassword from 'graphql/mutations/resetPassword.graphql';

import { extractErrors } from 'utils/graphql';

import paths from 'paths';
import t from 'i18n';

class ResetPassword extends Component {
  handleResetFormSubmit(resetPassword, { registrationNumber, phoneNumber }) {
    return resetPassword({
      variables: {
        registrationNumber,
        phoneNumber,
      },
    })
      .then((response) => {
        if (!response.data.resetPassword.errors) {
          toast(
            '임시비밀번호가 문자메시지로 발송되었습니다.\n새로 받은 비밀번호로 로그인 해주세요.'
          );

          paths.push(paths.login.index());
        }
      })
      .catch(() => {
        toast(t('errors.messages.generic'));
      });
  }

  render() {
    return (
      <section>
        <header>
          <h2>비밀번호 재발급</h2>
        </header>
        <Mutation mutation={resetPassword}>
          {(resetPassword, { data }) => (
            <ResetForm
              onSubmit={(values) =>
                this.handleResetFormSubmit(resetPassword, values)
              }
              errors={extractErrors(data, 'resetPassword')}
            />
          )}
        </Mutation>
      </section>
    );
  }
}

export default ResetPassword;
