import gql from 'graphql-tag.macro';

import businesses from '../fragments/businesses.fragment';

export default gql`
  query getBusinesses {
    businesses {
      ...businesses
    }
  }
  ${businesses}
`;
