import React from 'react';
import { useDispatch } from 'react-redux';
import { Mutation } from 'react-apollo';
import toast from 'react-hot-toast';

import { loginSuccess } from 'ducks/auth';

import Icon from 'components/Icon';
import Button from 'components/advanced/Button';

import loginWithKakao from 'graphql/mutations/loginWithKakao.graphql';

import t from 'i18n';

const KakaoLogin = ({ hidden = true }) => {
  const dispatch = useDispatch();

  const handleLogin = (loginMutation) => {
    window.Kakao.Auth.login({
      success: (auth) => {
        loginMutation({
          variables: {
            accessToken: auth.access_token,
          },
        })
          .then(({ data: { loginWithKakao: payload } }) => {
            if (payload.jwt) {
              dispatch(loginSuccess(payload.jwt));
            } else {
              toast('카카오로 가입된 캐시노트 계정이 없습니다.');
            }
          })
          .catch(() => {
            toast(t('errors.messages.generic'));
          });
      },
      fail: (err) => {
        if (err.error && err.error === 'access_denied') {
          toast(t('errors.messages.signup.kakao.access_denied'));
        } else {
          toast(t('errors.messages.generic'));
        }
      },
    });
  };

  return (
    <Mutation mutation={loginWithKakao}>
      {(login) =>
        !hidden && (
          <Button
            bg="goldenYellow"
            color="sealBrown"
            onClick={(e) => {
              e.preventDefault();
              handleLogin(login);
            }}
          >
            <Icon name="comment" mr={1} />
            카카오 로그인
          </Button>
        )
      }
    </Mutation>
  );
};

export default KakaoLogin;
