import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/advanced/Button';
import Modal from 'components/Mobile/Modal';

import KakaoLogin from './KakaoLogin';
import withKakao from 'hocs/withKakao';

import paths from 'paths';

class PasswordlessNotice extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <Modal title="카카오로 가입하셨네요!" onClose={onClose}>
        <React.Fragment>
          <p>
            카카오로 가입된 휴대전화번호입니다. "카카오로 로그인"하거나 임시
            비밀번호를 발급받아 로그인해주세요.
          </p>
          <KakaoLogin hidden={!this.props.isKakaoLoaded} />
          <Link to={paths.password.reset()}>
            <Button.Inverted mt={2} color="sealBrown">
              임시 비밀번호 발급
            </Button.Inverted>
          </Link>
        </React.Fragment>
      </Modal>
    );
  }
}

export default withKakao(PasswordlessNotice);
