import React from 'react';
import moment from 'moment';
import type { MomentInput } from 'moment';

import type { Credential } from 'types';
import type { ReportSectionProps } from './shared/types';
import type { PeriodType } from 'libs/period';

import HorizontalDivider from 'components/common/HorizontalDivider';
import Expandable from 'components/Expandable';
import FormatNumber from 'components/FormatNumber';
import Section from 'components/Mobile/Section';
import Notice from 'components/Notice';

import SectionHeader from './shared/SectionHeader';
import SectionDescription from './shared/SectionDescription';
import SectionRow from './shared/SectionRow';
import BarChart from './shared/BarChart';

import { getResults } from './shared/utils';

import useCredentials from 'hooks/useCredentials';

import { isBeforeSchedule } from 'utils/scraping';

const Divider = () => <HorizontalDivider borderColor="concrete" my="1rem" />;

const FeeAndPaymentList = ({
  fee,
  vatFee,
  payment,
}: {
  fee: number;
  vatFee: number;
  payment: number;
}) => (
  <ul>
    <SectionRow label="수수료">
      <FormatNumber.Won value={fee} />
    </SectionRow>
    {vatFee > 0 && (
      <SectionRow label="부가세 대리납부액">
        <FormatNumber.Won value={vatFee} />
      </SectionRow>
    )}
    <SectionRow label="정산액">
      <FormatNumber.Won value={payment} />
    </SectionRow>
  </ul>
);

interface UpcomingPayment {
  date: MomentInput;
  amount: number;
}

const UpcomingPayments = ({
  upcomingPayments,
}: {
  upcomingPayments?: UpcomingPayment[];
}) => {
  if (!upcomingPayments || upcomingPayments.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Divider />
      <ul>
        {upcomingPayments.map((upcomingPayment, index) => (
          <SectionRow
            key={index}
            label={`+ ${moment(upcomingPayment.date).format('D일 (dd)')} 입금`}
          >
            <FormatNumber.Won value={upcomingPayment.amount} />
          </SectionRow>
        ))}
      </ul>
    </React.Fragment>
  );
};

interface AmountByIssuer {
  issuer: string;
  amount: number | null;
}

const AmountByIssuers = ({
  amountByIssuers,
}: {
  amountByIssuers: AmountByIssuer[];
}) => {
  if (amountByIssuers.length === 0) {
    return null;
  }

  return (
    <Expandable
      maxHeightPx={0}
      buttonText="카드사별 매출 보기"
      initialExpanded={true}
    >
      <Divider />
      <ul>
        {amountByIssuers.map((amountByIssuer, index) => (
          <SectionRow key={index} label={amountByIssuer.issuer}>
            {amountByIssuer.amount === null ? (
              '수집 전'
            ) : (
              <FormatNumber.Won value={amountByIssuer.amount} />
            )}
          </SectionRow>
        ))}
      </ul>
    </Expandable>
  );
};

const DescriptionBody = ({
  period,
  crefia,
  isBefore,
}: {
  period: PeriodType;
  crefia: Credential;
  isBefore: boolean;
}) => {
  if (crefia.isInvalid) {
    return <SectionDescription.Invalidated label="카드매출" />;
  } else if (crefia.isValidating) {
    return <SectionDescription.Scraping />;
  }

  if (isBefore) {
    return (
      <SectionDescription.Important>
        {moment().hour() < 12
          ? '매일 오전 중에 어제 매출이 수집됩니다. 카드사 별로 수집 시간에 차이가 존재할 수 있습니다.'
          : '어제 매출내역 수집이 지연되고 있습니다. 단순 조회 지연으로 카드사 입금과 무관합니다.'}
      </SectionDescription.Important>
    );
  }

  return (
    <SectionDescription.Info>
      {period.toText()} 결제된 카드매출 정보입니다.
    </SectionDescription.Info>
  );
};

const Amounts = ({
  isBefore,
  result0,
}: {
  isBefore: boolean;
  result0: any;
}) => {
  if (result0.amount === 0) {
    return isBefore ? (
      <Notice.BeforeScraping compact />
    ) : (
      <Notice.Empty compact />
    );
  }

  return (
    <React.Fragment>
      <FeeAndPaymentList
        fee={result0.fee}
        vatFee={result0.vatFee}
        payment={result0.payment}
      />
      <UpcomingPayments upcomingPayments={result0.upcomingPayments} />
      <AmountByIssuers amountByIssuers={result0.amountByIssuers} />
    </React.Fragment>
  );
};

const CardSalesTransactionsSection = ({
  periods,
  data,
  sectionTitle,
  businessId,
}: ReportSectionProps) => {
  const credentials = useCredentials(businessId);
  const invalidRange = periods[0].isAfter(moment().subtract(1, 'day'));

  if (!credentials || !credentials.crefia) {
    return null;
  }

  const { crefia } = credentials;

  const cardSales = getResults({ data, key: 'cardSales', periods });

  const result0 = cardSales[0].result;

  const isBefore =
    result0.amount === 0 &&
    isBeforeSchedule(periods[0].date, 'cardSales', result0.missingDates);

  return (
    <Section bg="white">
      <SectionHeader
        title={sectionTitle}
        action={
          crefia.isValidating ? null : (
            <FormatNumber.Won value={result0.amount} />
          )
        }
      >
        <SectionDescription>
          <DescriptionBody
            period={periods[0]}
            crefia={crefia}
            isBefore={isBefore}
          />
        </SectionDescription>
      </SectionHeader>

      <BarChart.Simple results={cardSales} clickable />

      {invalidRange ? (
        <Notice.InvalidRange compact />
      ) : crefia.isValidating ? (
        <Notice.Scraping compact />
      ) : (
        <Amounts isBefore={isBefore} result0={result0} />
      )}
    </Section>
  );
};

export default CardSalesTransactionsSection;
