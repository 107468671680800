import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import type { PeriodType } from 'libs/period';

import Icon from 'components/Icon';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';

import { periodToDateRangeQuery } from 'hooks/useDateRange';
import usePath, { Path } from 'hooks/usePath';

import colors from 'constants/colors';

const LI = styled.li`
  padding-top: ${({ hasBorder }: { hasBorder?: boolean }) =>
    hasBorder ? '0.8rem' : 0};
  border-top: ${({ hasBorder }: { hasBorder?: boolean }) =>
    hasBorder ? `1px solid ${colors.concrete}` : 0};

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

const SectionRow = ({
  label,
  description,
  color = 'charcoal',
  onClick,
  children,
}: {
  label: string;
  description?: string;
  color?: string;
  onClick?: () => void;
  children: React.ReactNode;
}) => (
  <LI>
    <Flex
      alignItems="center"
      justifyContent="space-between"
      color={color}
      onClick={onClick}
    >
      <div>
        {label}
        {description && <small> ({description})</small>}
      </div>
      <div>
        {children}
        {onClick && <Icon.Right ml={1} color="lightgrey" />}
      </div>
    </Flex>
  </LI>
);

export interface SectionRowLinkProps {
  linkTo: Path;
  onClick?: () => void;
  period?: PeriodType;
  label: string;
  description?: string;
  children?: React.ReactNode;
  queryParams?: any;
}

const SectionRowLink = ({
  linkTo,
  onClick,
  period,
  label,
  description,
  children,
  queryParams,
}: SectionRowLinkProps) => {
  const link = usePath(linkTo);
  const toWithDateRangeQueries = period
    ? `${link}${periodToDateRangeQuery(period, queryParams)}`
    : link;

  return (
    <LI>
      <Link to={toWithDateRangeQueries} onClick={onClick}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          color="charcoal"
        >
          <div>
            {label}
            {description && <small> ({description})</small>}
          </div>
          <div>
            {children}
            <Icon.Right ml={1} color="lightgrey" />
          </div>
        </Flex>
      </Link>
    </LI>
  );
};

const ActionLink = ({
  linkTo,
  children,
}: {
  linkTo: Path;
  children: React.ReactNode;
}) => {
  const link = usePath(linkTo);

  return <Link to={link}>{children}</Link>;
};

const SectionRowAction = ({
  linkTo,
  onClick,
  children,
}: {
  linkTo?: Path;
  onClick?: () => void;
  children: React.ReactNode;
}) => {
  const action = (
    <Text textAlign="center" color="tin">
      + {children}
    </Text>
  );

  return (
    <LI hasBorder onClick={onClick}>
      {linkTo ? <ActionLink linkTo={linkTo}>{action}</ActionLink> : action}
    </LI>
  );
};

SectionRow.Link = SectionRowLink;
SectionRow.Action = SectionRowAction;

export default SectionRow;
