import React from 'react';

import Box from 'components/common/Box';
import { InvertedRound } from 'components/advanced/Button';

import paths from 'paths';

const LogoutButton = () => {
  return (
    <Box textAlign="center" py="24px">
      <InvertedRound
        fontSize="13px"
        color="mosque"
        onClick={() => paths.push(paths.logout())}
      >
        마이샵 연결 해제
      </InvertedRound>
    </Box>
  );
};

export default LogoutButton;
