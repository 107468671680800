import React from 'react';
import styled from 'styled-components/macro';

import colors from 'constants/colors';

const Main = styled.main`
  max-width: 500px;
  margin: auto;
  padding: 55px 0px 0px 0px;

  h1 {
    text-align: center;
    margin: 2rem 0 0.5rem;
  }

  section {
    padding: 0 28px;
    text-align: center;
  }

  section header {
    color: ${colors.teal700};
    text-align: center;
    margin-bottom: 30px;
  }

  section header h2 {
    font-size: 1.5rem;
    font-weight: bolder;
  }

  footer {
    margin-top: 2rem;
    font-size: 1rem;
    text-align: center;
    color: ${colors.tin};
  }

  button[type='submit'] {
    background-color: ${colors.teal700};
  }

  a {
    color: ${colors.teal700};
  }
`;

const Entry = ({ children }) => <Main>{children}</Main>;

export default Entry;
