const colors: { [key: string]: string } = {
  primary: '#02A6AC',
  primaryLight: '#00CCCC',
  primaryLighter: '#EEFAF9',
  primaryDark: '#008D93',
  secondary: '#0B357E',
  blizzard: '#99EBEB',
  columbiaBlue: '#B3E6FF',
  shamrock: '#3ED290',
  mosque: '#005660',
  maroon: '#C53A51',
  blueChalk: '#E8DBEF',
  carnation: '#F2514A',
  pink: '#F8C9CE',
  goldenYellow: '#F9E000',
  lightYellow: '#FFF580',
  chardonnay: '#FFCC80',
  sealBrown: '#3B1E1E',
  stone: '#333E48',
  charcoal: '#474747',
  tin: '#7F7F7F',
  darkgrey: '#AEAEAE',
  lightgrey: '#D7D7D7',
  mercury: '#E5E5E5',
  concrete: '#F2F2F2',
  ghost: '#FAFCFE',
  white: '#FFFFFF',
  overlay: 'rgba(0, 0, 0, 0.3)',

  /* new colors */
  gray100: '#FCFCFD',
  gray200: '#F7F7F8',
  gray300: '#E4E3E8',
  gray400: '#DFDEE3',
  gray500: '#9491A1',
  gray600: '#6F6C7A',
  gray700: '#2E2B3B',
  gray800: '#16141F',
  matchGreen100: '#FCFEF0',
  matchGreen200: '#EDF7BA',
  matchGreen300: '#D9EF6C',
  matchGreen400: '#BBD926',
  matchGreen500: '#96B800',
  matchGreen600: '#708807',
  matchGreen700: '#405502',
  matchGreen800: '#2C3905',
  orange100: '#FFFAF5',
  orange200: '#FFE5CC',
  orange300: '#FFCA99',
  orange400: '#FEB267',
  orange500: '#FF8717',
  orange600: '#E56F00',
  orange700: '#994A00',
  orange800: '#663300',
  red100: '#FFFAFB',
  red200: '#FDECEE',
  red300: '#FDD7DC',
  red400: '#FB93A1',
  red500: '#EB1431',
  red600: '#BC101F',
  red700: '#940000',
  red800: '#5A0202',
  slateBlue100: '#F6F8FE',
  slateBlue200: '#DEE7FC',
  slateBlue300: '#C4D0FD',
  slateBlue400: '#8FA7FF',
  slateBlue500: '#5F5CFF',
  slateBlue600: '#3E34AD',
  slateBlue700: '#1D1584',
  slateBlue800: '#18165A',
  teal100: '#F6FDFD',
  teal200: '#EDFCFC',
  teal300: '#D3F8F8',
  teal400: '#8CF2EF',
  teal500: '#11D4CE',
  teal600: '#00BDBD',
  teal700: '#037382',
  teal800: '#005660',
};

export default colors;
