export const ko = {
  credential: {
    added:
      '인증정보가 등록되었습니다. 연결여부 확인 및 정보 수집이 완료되면 안내해드리겠습니다.',
    exists:
      '이미 등록된 인증정보가 존재합니다. 변경을 원하시는 경우 기존 인증정보를 삭제하고 새로 등록해주세요.',
  },
  subscription: {
    plan: {
      upgraded: '지금부터 고급형 서비스를 이용하실 수 있습니다.',
      notOwner: '이용권 변경은 최고관리자만 가능합니다.',
    },
  },
  coupon: {
    notOwner: '쿠폰 등록은 최고관리자만 가능합니다.',
    crefiaNotValid: '쿠폰 등록은 카드매출 연동 완료 후 가능합니다.',
    registered: '사업장에 쿠폰이 등록되었습니다.',
  },
  paymentMethod: {
    notOwner: '결제정보 등록/변경은 최고관리자만 가능합니다.',
  },
  errors: {
    messages: {
      generic:
        '일시적인 장애가 발생하였습니다.\n잠시 후 다시 시도해주시기 바랍니다.',
      sudoExpired:
        '세션 시간이 만료되었습니다. 개인정보 보호를 위해 비밀번호를 다시 입력해주세요.',
      required: '필수로 입력해야하는 값입니다.',
      invalid: '올바르지 않은 형식입니다.',
      signup: {
        kakao: {
          access_denied: '카카오로 로그인 권한 신청이 거부되었습니다.',
        },
      },
      payment: '등록된 결제정보로 결제가 불가능합니다.',
    },
  },
};

const memoized: { [key: string]: Function } = {};

const t = (path: string) => {
  if (!memoized[path]) {
    // eslint-disable-next-line no-new-func
    memoized[path] = Function('obj', `return obj.${path};`);
  }
  return memoized[path](ko);
};

export default t;
