import React from 'react';
import { ResponsiveContainer } from 'recharts';

import Box from 'components/common/Box';
import Text from 'components/common/Text';
import Icon from 'components/Icon';

interface ChartContainerProps {
  clickable?: boolean;
  height?: number;
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  clickable,
  children,
  height = 180,
  ...rest
}) => (
  <Box mb="1rem" {...rest}>
    <ResponsiveContainer width="100%" height={height}>
      {children}
    </ResponsiveContainer>
    {clickable && (
      <Text width="100%" fontSize="0.8rem" color="darkgrey" textAlign="center">
        <Icon name="bookmark" color="primaryLight" mr={1} />
        막대그래프를 누르면 해당 기간으로 이동합니다.
      </Text>
    )}
  </Box>
);

export default ChartContainer;
