import React, { Component } from 'react';
import styled from 'styled-components/macro';

import ErrorLayout from 'layouts/Error';

import colors from 'constants/colors';

interface ChunkLoadError extends Error {
  name: 'ChunkLoadError';
  type: 'missing' | string | undefined;
}

const Link = styled.a`
  display: block;
  margin-top: 1.5rem;
  color: ${colors.primary};
  cursor: pointer;
`;

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error: ChunkLoadError | Error) {
    if (
      error.name === 'ChunkLoadError' &&
      (error as ChunkLoadError).type !== 'timeout' &&
      window.location.hash !== '#retry'
    ) {
      window.location.hash = '#retry';
      window.location.reload(true);
      return;
    }

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorLayout>
          <h1>오류가 발생하였습니다.</h1>
          <p>
            인터넷 연결이 바르지 않거나 서비스가 요청을 처리할 수 없는
            상태입니다.
            <br />
            잠시뒤에 다시 시도해주세요.
            <br />
            문제가 지속될 시 고객센터로 문의해 주시면 친절하게 안내해
            드리겠습니다.
          </p>
          <Link onClick={() => window.location.reload(true)}>
            화면 새로고침
          </Link>
          <Link href="https://cashnote.zendesk.com/hc/ko/requests/new">
            고객센터 문의하기
          </Link>
        </ErrorLayout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
