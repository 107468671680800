import React from 'react';
import styled from 'styled-components/macro';

import type { LinkProps } from 'components/Link';

import Box from 'components/common/Box';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';
import Icon from 'components/Icon';
import Link from 'components/Link';

import colors from 'constants/colors';

const Section = ({
  id,
  bg,
  children,
  ...props
}: {
  id?: string;
  bg?: string;
  css?: any;
  children: React.ReactNode;
}) => (
  <Flex
    id={id}
    className="mobile-section"
    flexDirection="column"
    pt="1.5rem"
    pb="1.5rem"
    px="1.3rem"
    bg={bg}
    {...props}
  >
    {children}
  </Flex>
);

const HeaderComponent = ({
  children,
  ...props
}: {
  children: React.ReactNode;
}) => (
  <Flex
    flexDirection="column"
    flexWrap="wrap"
    pb="0.5rem"
    mb="1rem"
    css={{
      borderBottom: `1px solid ${colors.mercury}`,
    }}
    {...props}
  >
    {children}
  </Flex>
);

const HeaderTitle = styled.h3`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
`;

const HeaderSubtitle = ({
  children,
  ...props
}: {
  children: React.ReactNode;
}) => (
  <Text ml={1} fontSize="0.9rem" {...props}>
    {children}
  </Text>
);

const HeaderLinkComponent = styled(Link)`
  margin-left: auto;
  font-size: 0.9rem;
  color: ${colors.primaryDark};
`;

interface HeaderLinkProps {
  title: string;
  to: LinkProps['to'];
}

export const SectionHeaderLink = ({ title, to }: HeaderLinkProps) => (
  <HeaderLinkComponent to={to}>
    {title} <Icon.Right />
  </HeaderLinkComponent>
);

export const SectionHeaderDescription = ({
  children,
  ...props
}: {
  children: React.ReactNode;
}) => (
  <Box mt="0.5rem" fontSize="0.9rem" color={colors.tin} {...props}>
    {children}
  </Box>
);

interface HeaderProps {
  title?: string;
  subtitle?: string;
  action?: React.ReactNode;
  children?: React.ReactNode;
}

export const SectionHeader = ({
  title,
  subtitle,
  action,
  children,
  ...rest
}: HeaderProps) => {
  return (
    <HeaderComponent {...rest}>
      {title && (
        <HeaderTitle>
          {title}
          {subtitle && <HeaderSubtitle>{subtitle}</HeaderSubtitle>}
          {action}
        </HeaderTitle>
      )}
      {children}
    </HeaderComponent>
  );
};

export default Section;
