import system from '@rebass/components';

const Container = system(
  {
    maxWidth: '768px',
    mx: 'auto',
  },
  'space',
  'maxWidth'
);

export default Container;
