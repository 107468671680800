import React, { Component } from 'react';
import { Formik } from 'formik';

import Container from 'components/common/Container';
import Text from 'components/common/Text';
import Form from 'components/Form';
import Icon from 'components/Icon';

import t from 'i18n';

import { isValidPhoneNumber } from 'utils/phone';
import { isValidRegistrationNumber } from 'utils/registrationNumber';

class ResetForm extends Component {
  componentDidUpdate() {
    this.formik.setErrors(this.props.errors);
  }

  validateForm = (values) => {
    const errors = {};
    if (
      values.registrationNumber &&
      !isValidRegistrationNumber(values.registrationNumber)
    ) {
      errors.registrationNumber = t('errors.messages.invalid');
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = t('errors.messages.required');
    } else if (!isValidPhoneNumber(values.phoneNumber)) {
      errors.phoneNumber = t('errors.messages.invalid');
    }
    return errors;
  };

  handleFormSubmit = (values, { setSubmitting }) => {
    this.props
      .onSubmit({
        registrationNumber: values.registrationNumber,
        phoneNumber: values.phoneNumber,
      })
      .then(() => setSubmitting(false))
      .catch(() => setSubmitting(false));
  };

  render() {
    return (
      <Formik
        ref={(formik) => {
          this.formik = formik;
        }}
        initialValues={{
          registrationNumber: '',
          phoneNumber: '',
        }}
        validate={this.validateForm}
        onSubmit={this.handleFormSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group
              id="phoneNumber"
              label="휴대전화번호"
              error={touched.phoneNumber && errors.phoneNumber}
            >
              <Form.Phone
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
              />
            </Form.Group>
            <Form.Group
              id="registrationNumber"
              label="사업자등록번호"
              error={touched.registrationNumber && errors.registrationNumber}
            >
              <Form.RegistrationNumber
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.registrationNumber}
              />
            </Form.Group>
            <Form.Submit disabled={isSubmitting}>비밀번호 재발급</Form.Submit>
            <Container maxWidth="256px" my={3}>
              <Text textAlign="center" color="tin" fontSize="0.9rem">
                <Icon name="info-circle" mr={1} />
                캐시노트에 등록된 사업장이 없다면 사업자등록번호 란은
                비워두세요.
              </Text>
            </Container>
          </Form>
        )}
      />
    );
  }
}

export default ResetForm;
