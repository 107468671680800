import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

import Icon from 'components/Icon';
import { Absolute } from 'components/common/Position';
import Box from 'components/common/Box';
import Text from 'components/common/Text';
import HorizontalDivider from 'components/common/HorizontalDivider';

import colors from 'constants/colors';

export const Header = ({ children, ...rest }) => (
  <Text as="header" fontWeight="bold" mb="1rem" fontSize="1.25rem" {...rest}>
    {children}
    <HorizontalDivider mb={0} mt="0.5rem" />
  </Text>
);

const CloseButton = ({ onClick }) => (
  <Absolute top="1rem" right={0}>
    <Box data-testid="close-button" onClick={onClick} px={2}>
      <Icon name="close" size="1.25rem" color="tin" />
    </Box>
  </Absolute>
);

const Modal = ({
  title,
  onClose,
  closeCallback = () => {},
  hideCloseButton = false,
  children,
  overlayStyle = {},
  contentStyle = {},
  ...rest
}) => {
  useEffect(() => {
    window.Popup.open(onClose || closeCallback);
    return () => window.Popup.close(false);
  }, [onClose, closeCallback]);

  return (
    <ReactModal
      isOpen
      bodyOpenClassName="ReactModal__Mobile__Body--open"
      contentLabel={title}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={{
        overlay: {
          overflow: 'auto',
          backgroundColor: colors.overlay,
          zIndex: 99999999,
          ...overlayStyle,
        },
        content: {
          position: 'relative',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '290px',
          margin: '40px auto',
          padding: '1rem',
          ...contentStyle,
        },
      }}
      {...rest}
    >
      {!hideCloseButton && <CloseButton onClick={onClose} />}
      {title && <Header>{title}</Header>}
      {children}
    </ReactModal>
  );
};

export default Modal;
