import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { ApolloLink, Observable } from 'apollo-link';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import storage from 'local-storage-fallback';

import introspectionQueryResultData from './fragmentTypes.json';

const isQuery = (operation) =>
  operation.query.definitions[0].operation === 'query';

const request = (operation) => {
  const token = storage.getItem('token');
  const { headers } = operation.getContext();

  operation.setContext({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  freezeResults: true,
});

const client = new ApolloClient({
  assumeImmutableResults: true,
  link: ApolloLink.from([
    new RetryLink({
      delay: {
        initial: 600,
      },
      attempts: {
        max: 3,
        retryIf: (error, operation) => {
          const operationIsLogin = operation.operationName.match(/login/);
          return !!error && (operationIsLogin || isQuery(operation));
        },
      },
    }),
    requestLink,
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_API_ENDPOINT,
    }),
  ]),
  cache,
});

export default client;
