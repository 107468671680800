import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { MODE } from 'types';

import Box from 'components/common/Box';
import Flex from 'components/common/Flex';
import { Fixed, Relative, Absolute } from 'components/common/Position';
import Border from 'components/common/Border';
import Text from 'components/common/Text';
import Button from 'components/Button';
import Icon from 'components/Icon';

import { updateDateRangeQueries } from 'hooks/useDateRange';

import { Range } from 'libs/period';
import colors from 'constants/colors';

function formatDate({ mode, date, showYear = false, showDay = false }) {
  switch (mode) {
    case MODE.DAY:
      let dateFormat = 'M월 D일';
      if (showYear) {
        dateFormat = `YY년 ${dateFormat}`;
      }
      if (showDay) {
        dateFormat = `${dateFormat}(dd)`;
      }

      return date.format(dateFormat);
    case MODE.WEEK:
      const thursday = moment(date).day(4);
      return showYear
        ? thursday.format(`YY년 M월 ${Math.ceil(thursday.date() / 7)}주차`)
        : thursday.format(`M월 ${Math.ceil(thursday.date() / 7)}주차`);
    case MODE.MONTH:
      return showYear ? date.format('YY년 M월') : date.format('M월');
    // no default
  }
}

const ModeLabel = styled.label`
  input {
    margin-right: 4px;
  }
`;

const DateInput = styled.input.attrs(() => ({
  type: 'text',
}))`
  width: 105px;
  font-size: 16px;
  text-align: center;
`;

class DateRangeModeSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.initialMode,
      startDate: props.initialStartDate || null,
      endDate: props.initialEndDate || null,
    };
  }

  handleModeChange = () => {
    const { mode, startDate, endDate } = this.state;

    switch (mode) {
      case MODE.DAY:
        this.props.onModeChange({
          mode,
          startDate: moment(startDate).startOf('day'),
          endDate: moment(startDate).endOf('day'),
        });
        return;
      case MODE.WEEK:
        this.props.onModeChange({
          mode,
          startDate: moment(startDate).startOf('isoWeek'),
          endDate: moment(startDate).endOf('isoWeek'),
        });
        return;
      case MODE.MONTH:
        this.props.onModeChange({
          mode,
          startDate: moment(startDate).startOf('month'),
          endDate: moment(startDate).endOf('month'),
        });
        return;
      case MODE.CUSTOM:
        this.props.onModeChange({ mode, startDate, endDate });
        return;
      // no default
    }
  };

  render() {
    return (
      <Box width="100%" bg="white" p={2} data-testid="mode-selector">
        <Box mb={2}>
          <ModeLabel>
            <input
              type="radio"
              name="mode"
              value={MODE.DAY}
              onChange={(e) => {
                this.setState({
                  mode: e.target.value,
                });
              }}
              checked={this.state.mode === MODE.DAY}
            />
            일 단위
          </ModeLabel>
        </Box>
        <Box mb={2}>
          <ModeLabel>
            <input
              type="radio"
              name="mode"
              value={MODE.WEEK}
              onChange={(e) => {
                this.setState({
                  mode: e.target.value,
                });
              }}
              checked={this.state.mode === MODE.WEEK}
            />
            주 단위
          </ModeLabel>
        </Box>
        <Box mb={2}>
          <ModeLabel>
            <input
              type="radio"
              name="mode"
              value={MODE.MONTH}
              onChange={(e) => {
                this.setState({
                  mode: e.target.value,
                });
              }}
              checked={this.state.mode === MODE.MONTH}
            />
            월 단위
          </ModeLabel>
        </Box>
        <Flex>
          <Box>
            <ModeLabel>
              <input
                type="radio"
                name="mode"
                value={MODE.CUSTOM}
                onChange={(e) => {
                  this.setState({
                    mode: e.target.value,
                  });
                }}
                checked={this.state.mode === MODE.CUSTOM}
              />
              직접 설정
            </ModeLabel>
          </Box>
          <Flex flexDirection="column">
            <Flex>
              <Box mx={2}>
                <DatePicker
                  selected={this.state.startDate}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={moment('2016-07-01')}
                  maxDate={this.props.maxDate}
                  onChange={(startDate) => {
                    if (startDate.isBefore(this.state.endDate)) {
                      this.setState({
                        startDate,
                        endDate: moment.min(
                          this.state.endDate,
                          moment(startDate).add(30, 'days'),
                          this.props.maxDate
                        ),
                      });
                    } else {
                      this.setState({
                        startDate,
                        endDate: moment.min(
                          moment(startDate).add(30, 'days'),
                          this.props.maxDate
                        ),
                      });
                    }
                  }}
                  customInput={<DateInput />}
                  dateFormat="YYYY/MM/DD"
                  readOnly
                  withPortal
                  disabled={this.state.mode !== MODE.CUSTOM}
                />
              </Box>
              <Box>
                <DatePicker
                  selected={this.state.endDate}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.startDate}
                  maxDate={moment.min(
                    moment(this.state.startDate).add(30, 'days'),
                    this.props.maxDate
                  )}
                  onChange={(endDate) => {
                    this.setState({
                      endDate,
                    });
                  }}
                  customInput={<DateInput />}
                  dateFormat="YYYY/MM/DD"
                  readOnly
                  withPortal
                  disabled={this.state.mode !== MODE.CUSTOM}
                />
              </Box>
            </Flex>
            {this.state.mode === MODE.CUSTOM && (
              <Text fontSize="12px" ml={2} mt={1} color="tin">
                (기간 선택은 최대 30일)
              </Text>
            )}
          </Flex>
        </Flex>
        <Button
          mt="0.8rem"
          width="100%"
          bg="white"
          color="black"
          borderColor="mercury"
          onClick={this.handleModeChange}
        >
          적용
        </Button>
      </Box>
    );
  }
}

class DateRangeSelector extends Component {
  static propTypes = {
    mode: PropTypes.oneOf([MODE.DAY, MODE.WEEK, MODE.MONTH, MODE.CUSTOM])
      .isRequired,
    modeFixed: PropTypes.bool,
  };

  static defaultProps = {
    modeFixed: false,
  };

  state = {
    showModeSelector: false,
  };

  handlePreviousClick = () => {
    if (this.state.showModeSelector) {
      return;
    }

    const { mode, startDate, endDate } = this.props;
    const newStartDate = moment(startDate);
    const newEndDate = moment(endDate);

    switch (mode) {
      case MODE.DAY:
        newStartDate.subtract(1, 'days').startOf('day');
        newEndDate.subtract(1, 'days').endOf('day');
        break;
      case MODE.WEEK:
        newStartDate.subtract(1, 'weeks').startOf('isoWeek');
        newEndDate.subtract(1, 'weeks').endOf('isoWeek');
        break;
      case MODE.MONTH:
        newStartDate.subtract(1, 'months').startOf('month');
        newEndDate.subtract(1, 'months').endOf('month');
        break;
      case MODE.CUSTOM:
        const durationDays = moment.duration(endDate.diff(startDate)).days();
        newStartDate.subtract(durationDays + 1, 'days').startOf('day');
        newEndDate.subtract(durationDays + 1, 'days').endOf('day');
      // no default
    }

    updateDateRangeQueries({
      mode,
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  handleNextClick = () => {
    if (this.state.showModeSelector) {
      return;
    }

    const { mode, startDate, endDate } = this.props;
    const newStartDate = moment(startDate);
    const newEndDate = moment(endDate);

    switch (mode) {
      case MODE.DAY:
        newStartDate.add(1, 'days').startOf('day');
        newEndDate.add(1, 'days').endOf('day');
        break;
      case MODE.WEEK:
        newStartDate.add(1, 'weeks').startOf('isoWeek');
        newEndDate.add(1, 'weeks').endOf('isoWeek');
        break;
      case MODE.MONTH:
        newStartDate.add(1, 'months').startOf('month');
        newEndDate.add(1, 'months').endOf('month');
        break;
      case MODE.CUSTOM:
        const durationDays = moment.duration(endDate.diff(startDate)).days();
        newStartDate.add(durationDays + 1, 'days').startOf('day');
        newEndDate.add(durationDays + 1, 'days').endOf('day');
      // no default
    }

    updateDateRangeQueries({
      mode,
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  renderPreviousDate() {
    const { mode, startDate } = this.props;

    const previousDate = moment(startDate);
    switch (mode) {
      case MODE.DAY:
        previousDate.subtract(1, 'days');
        break;
      case MODE.WEEK:
        previousDate.subtract(1, 'weeks');
        break;
      case MODE.MONTH:
        previousDate.subtract(1, 'months');
        break;
      // no default
    }

    return (
      <Box
        color="charcoal"
        onClick={() => this.handlePreviousClick()}
        data-testid="previous-date"
      >
        <Icon.Left color="tin" mr={1} />
        {mode === MODE.CUSTOM
          ? '이전'
          : formatDate({
              mode,
              date: previousDate,
              showYear:
                mode === MODE.WEEK
                  ? moment(startDate).day(4).year() !==
                    moment(previousDate).day(4).year()
                  : startDate.year() !== previousDate.year(),
            })}
      </Box>
    );
  }

  renderCurrentDate() {
    const { mode, startDate, endDate, modeFixed } = this.props;

    return (
      <Flex
        onClick={() =>
          !modeFixed &&
          this.setState((state) => ({
            showModeSelector: !state.showModeSelector,
          }))
        }
        data-testid="current-date"
        alignItems="center"
      >
        <Text as="span" fontWeight="bold" mr={1}>
          {mode === MODE.CUSTOM
            ? new Range(startDate, endDate).toText()
            : formatDate({
                mode,
                date: startDate,
                showDay: mode === MODE.DAY,
              })}
        </Text>
        {!modeFixed && (
          <Icon name="caret-down" color="stone" size="1.3rem" mt="2px" />
        )}
      </Flex>
    );
  }

  renderNextDate() {
    const { mode, startDate, endDate } = this.props;
    const nextDate = moment(endDate);

    switch (mode) {
      case MODE.DAY:
        nextDate.add(1, 'days');
        break;
      case MODE.WEEK:
        nextDate.add(1, 'weeks').startOf('isoWeek');
        break;
      case MODE.MONTH:
        nextDate.add(1, 'months').startOf('month');
        break;
      // no default
    }

    return (
      <Box
        color="charcoal"
        onClick={() => this.handleNextClick()}
        data-testid="next-date"
      >
        {mode === MODE.CUSTOM
          ? '다음'
          : formatDate({
              mode,
              date: nextDate,
              showYear:
                mode === MODE.WEEK
                  ? moment(startDate).day(4).year() !==
                    moment(nextDate).day(4).year()
                  : endDate.year() !== nextDate.year(),
            })}
        <Icon.Right color="tin" ml={1} />
      </Box>
    );
  }

  render() {
    const { mode, startDate, endDate, maxDate } = this.props;

    return (
      <Border borderBottom={1} borderColor="mercury" bg="white">
        <Relative py="10px" px={2}>
          <Flex justifyContent="space-between">
            {this.renderPreviousDate()}
            {this.renderCurrentDate()}
            {this.renderNextDate()}
          </Flex>
          {this.state.showModeSelector && (
            <React.Fragment>
              <Fixed
                top={200}
                right={0}
                bottom={0}
                left={0}
                bg={colors.overlay}
              />
              <Absolute top="100%" left="0" right="0" zIndex="1000" mt="1px">
                <DateRangeModeSelector
                  initialMode={mode}
                  initialStartDate={startDate}
                  initialEndDate={endDate}
                  maxDate={maxDate}
                  onModeChange={(values) => {
                    this.setState({
                      showModeSelector: false,
                    });
                    updateDateRangeQueries(values);
                  }}
                />
              </Absolute>
            </React.Fragment>
          )}
        </Relative>
      </Border>
    );
  }
}

export default DateRangeSelector;
