import React, { useState } from 'react';
import { ReactHeight } from 'react-height';

import { Fixed } from 'components/common/Position';

const FixedHeight = ({ children, ...rest }) => {
  const [height, setHeight] = useState(null);

  return (
    <div
      style={{
        height,
      }}
    >
      <Fixed
        css={{
          minWidth: '320px',
          maxWidth: '768px',
        }}
        width="100%"
        zIndex={1000}
        {...rest}
      >
        <ReactHeight onHeightReady={(height) => setHeight(height)}>
          {children}
        </ReactHeight>
      </Fixed>
    </div>
  );
};

export default FixedHeight;
