import React from 'react';
import system from '@rebass/components';

const I = system({ is: 'i' }, 'space', 'color', (props) => ({
  width: '1em',
  textAlign: 'center',
  fontSize: props.size,
}));

const Icon = ({ name, ...rest }) => (
  <I className={`fa fa-${name}`} aria-hidden="true" {...rest} />
);

const Circle = system(
  {
    p: 1,
  },
  {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  'space',
  'color'
);

const CircleIcon = ({ size, name, ...rest }) => (
  <Circle {...rest}>
    <Icon size={size} name={name} />
  </Circle>
);

Icon.Circle = CircleIcon;

const SpoqaGlyphicon = system({ is: 'i' }, 'space', 'color', (props) => ({
  display: 'inline-block',
  fontSize: props.size,
  fontWeight: 'bold',
  fontStyle: 'normal',
}));

Icon.Left = (props) =>
  document.body.classList.contains('spoqa-han-sans-loaded') ? (
    <SpoqaGlyphicon {...props}>&#xE000;</SpoqaGlyphicon>
  ) : (
    <Icon name="chevron-left" {...props} />
  );

Icon.Right = (props) =>
  document.body.classList.contains('spoqa-han-sans-loaded') ? (
    <SpoqaGlyphicon {...props}>&#xE001;</SpoqaGlyphicon>
  ) : (
    <Icon name="chevron-right" {...props} />
  );

export default Icon;
