import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro';

import colors from 'constants/colors';

const ThemeProvider = (props: any) => (
  <StyledThemeProvider theme={{ colors }} {...props} />
);

export default ThemeProvider;
