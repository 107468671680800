import { useQuery } from '@apollo/react-hooks';

import BusinessCredentials from 'libs/BusinessCredentials';

import getCredentials from 'graphql/queries/getCredentials.graphql';

function useCredentials(businessId: string): BusinessCredentials | null {
  const { data } = useQuery(getCredentials, {
    variables: { businessId, types: ['CREFIA'] },
  });

  return data?.business ? new BusinessCredentials(data) : null;
}

export default useCredentials;
