import React from 'react';

import Flex from 'components/common/Flex';
import Box from 'components/common/Box';
import Text from 'components/common/Text';

interface SectionHeaderProps {
  title: string;
  action?: React.ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  action,
  children,
}) => (
  <Box pb="0.5rem" mb="1rem" px="1.3rem" mx="-1.3rem">
    <Flex alignItems="center" justifyContent="space-between" fontSize="1.2rem">
      <Text color="stone" fontWeight="bold" fontSize="1.5rem">
        {title}
      </Text>
      {action}
    </Flex>
    {children}
  </Box>
);

export default SectionHeader;
