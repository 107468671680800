import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import useAuthToken from 'hooks/useAuthToken';

import EntryLayout from 'layouts/Entry';

import SelectLogin from './SelectLogin';
import PasswordReset from './ResetPassword';
import LoginWithPhone from './LoginWithPhone';
import Retrieve from './RetrieveAccount';

import paths from 'paths';

const Auth = () => {
  const isLoggedIn = !!useAuthToken();

  if (isLoggedIn) {
    return <Redirect to={paths.root()} />;
  }

  return (
    <Switch>
      <Route exact path="/login">
        <SelectLogin />
      </Route>
      <Route exact path="/login/account">
        <EntryLayout>
          <LoginWithPhone />
        </EntryLayout>
      </Route>
      <Route exact path="/account/retrieve">
        <EntryLayout>
          <Retrieve />
        </EntryLayout>
      </Route>
      <Route exact path="/password/reset">
        <EntryLayout>
          <PasswordReset />
        </EntryLayout>
      </Route>
    </Switch>
  );
};

export default Auth;
