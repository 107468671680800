import gql from 'graphql-tag.macro';

export default gql`
  query getSalesSummary(
    $businessId: ID!
    $dateRange0: DateRangeInput!
    $dateRange1: DateRangeInput!
    $dateRange2: DateRangeInput!
    $dateRange3: DateRangeInput!
    $dateRange4: DateRangeInput!
    $dateRange5: DateRangeInput!
    $dateRange6: DateRangeInput!
  ) {
    business(businessId: $businessId) {
      id

      cardSales0: cardSalesTransactionsSummary(dateRange: $dateRange0) {
        amount
        fee
        vatFee
        payment
        count
        newAmount
        returningAmount
        upcomingPayments {
          date
          amount
        }
        amountByIssuers {
          issuer
          amount
        }
        missingDates
      }
      cardSales1: cardSalesTransactionsSummary(dateRange: $dateRange1) {
        amount
      }
      cardSales2: cardSalesTransactionsSummary(dateRange: $dateRange2) {
        amount
      }
      cardSales3: cardSalesTransactionsSummary(dateRange: $dateRange3) {
        amount
      }
      cardSales4: cardSalesTransactionsSummary(dateRange: $dateRange4) {
        amount
      }
      cardSales5: cardSalesTransactionsSummary(dateRange: $dateRange5) {
        amount
      }
      cardSales6: cardSalesTransactionsSummary(dateRange: $dateRange6) {
        amount
      }
    }
  }
`;
