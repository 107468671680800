import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import useSearchParams from 'hooks/useSearchParams';
import useStorage from 'hooks/useStorage';

import PrivateRoute from 'components/PrivateRoute';
import Auth from 'pages/Auth';
import Logout from 'pages/Auth/Logout';
import WeeklyReports from 'pages/WeeklyReports';

import paths from 'paths';

const Root = () => {
  const registrationNumber = useSearchParams().registration_number;
  const [storedRegstrationNumber, setItem] = useStorage(
    'MYSHOP_REGISTRATION_NUMBER'
  );

  useEffect(() => {
    if (
      typeof registrationNumber === 'string' &&
      registrationNumber !== storedRegstrationNumber
    ) {
      setItem(registrationNumber);
      paths.removeSearchKey('registration_number');
    }
  }, [registrationNumber, storedRegstrationNumber, setItem]);

  return (
    <Switch>
      <PrivateRoute exact path="/">
        <WeeklyReports />
      </PrivateRoute>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route>
        <Auth />
      </Route>
    </Switch>
  );
};

export default Root;
