import { extractEdgeNodes } from 'utils/graphql';

export interface Credential {
  id: string;
  status: 'UNCONFIRMED' | 'CONFIRMED' | 'VALIDATING' | 'INVALIDATED';
  __typename: string;
}

export function isAggregated(
  credential?: CredentialWithStatusProps | CredentialsWithStatusProps
): credential is CredentialsWithStatusProps {
  if (!credential) {
    return false;
  }

  return 'areEmpty' in credential;
}

export function isValid(credential: Credential): boolean {
  return credential.status === 'CONFIRMED';
}

export function isInvalid(credential: Credential): boolean {
  return credential.status === 'INVALIDATED';
}

export function isValidating(credential: Credential): boolean {
  return (
    credential.status === 'VALIDATING' || credential.status === 'UNCONFIRMED'
  );
}

export function hasValidCredential(credentials: Credential[]): boolean {
  return credentials.some(isValid);
}

export function allCredentialsInvalid(credentials: Credential[]): boolean {
  return credentials.length > 0 && credentials.every(isInvalid);
}

export function allCredentialsValid(credentials: Credential[]): boolean {
  return credentials.length > 0 && credentials.every(isValid);
}

export function hasInvalidCredentials(credentials: Credential[]): boolean {
  return credentials.length > 0 && credentials.some(isInvalid);
}

export function allCredentialsValidating(credentials: Credential[]): boolean {
  return credentials.length > 0 && credentials.every(isValidating);
}

export function hasValidatingCredentials(credentials: Credential[]): boolean {
  return credentials.length > 0 && credentials.some(isValidating);
}

export interface CredentialWithStatusProps extends Credential {
  isValid: boolean;
  isInvalid: boolean;
  isValidating: boolean;
}

function withStatusProps(
  credential?: Credential
): CredentialWithStatusProps | undefined {
  if (!credential) {
    return undefined;
  }

  return {
    ...credential,
    isValid: isValid(credential),
    isInvalid: isInvalid(credential),
    isValidating: isValidating(credential),
  };
}

export interface CredentialsWithStatusProps
  extends Array<CredentialWithStatusProps> {
  areEmpty: boolean;
  someAreValid: boolean;
  allAreValid: boolean;
  allAreInvalid: boolean;
  allAreValidating: boolean;
  someAreInvalid: boolean;
  someAreValidating: boolean;
}

class BusinessCredentials {
  public nodes: CredentialWithStatusProps[];

  constructor(data: any) {
    this.nodes = extractEdgeNodes(
      data.business.credentials
    ).map((node: Credential) => withStatusProps(node));
  }

  findByTypenames(
    ...typenames: string[]
  ): CredentialWithStatusProps | undefined {
    return this.nodes.find((node) => typenames.includes(node.__typename));
  }

  filterByTypenames(...typenames: string[]): CredentialsWithStatusProps {
    const credentials = this.nodes.filter((node) =>
      typenames.includes(node.__typename)
    );

    return Object.defineProperties(credentials, {
      areEmpty: { value: credentials.length === 0 },
      someAreValid: { value: hasValidCredential(credentials) },
      allAreValid: { value: allCredentialsValid(credentials) },
      allAreInvalid: { value: allCredentialsInvalid(credentials) },
      allAreValidating: { value: allCredentialsValidating(credentials) },
      someAreInvalid: { value: hasInvalidCredentials(credentials) },
      someAreValidating: { value: hasValidatingCredentials(credentials) },
    });
  }

  get cardFranchises() {
    return this.filterByTypenames('CardFranchiseCredential');
  }

  get deliveryApps() {
    return this.filterByTypenames(
      'BaeminCredential',
      'YogiyoCredential',
      'BaedaltongCredential'
    );
  }

  get baemin() {
    return this.findByTypenames('BaeminCredential');
  }

  get yogiyo() {
    return this.findByTypenames('YogiyoCredential');
  }

  get baedaltong() {
    return this.findByTypenames('BaedaltongCredential');
  }

  get bankAccounts() {
    return this.filterByTypenames('BankAccountCredential');
  }

  get cards() {
    return this.filterByTypenames(
      'CardLoginCredential',
      'CardCertificateCredential'
    );
  }

  get hometaxes() {
    return this.filterByTypenames(
      'HometaxLoginCredential',
      'HometaxCertificateCredential'
    );
  }

  get hometaxLogin() {
    return this.findByTypenames('HometaxLoginCredential');
  }

  get hometaxCertificate() {
    return this.findByTypenames('HometaxCertificateCredential');
  }

  get crefia() {
    return this.findByTypenames(
      'CrefiaLoginCredential',
      'CrefiaBusinessCredential'
    );
  }

  get crefiaLogin() {
    return this.findByTypenames('CrefiaLoginCredential');
  }

  get crefiaBusiness() {
    return withStatusProps(
      this.nodes.find(
        (node) =>
          (node.__typename === 'CrefiaLoginCredential' &&
            // @ts-ignore
            node.registrationNumber) ||
          node.__typename === 'CrefiaBusinessCredential'
      )
    );
  }

  get utilityBills() {
    return this.filterByTypenames(
      'KepcoCredential',
      'GiroPhoneCredential',
      'GiroWaterCredential'
    );
  }

  get kepcos() {
    return this.filterByTypenames('KepcoCredential');
  }

  get giroPhone() {
    return this.findByTypenames('GiroPhoneCredential');
  }

  get giroWater() {
    return this.findByTypenames('GiroWaterCredential');
  }
}

export default BusinessCredentials;
