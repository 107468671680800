import React, { useEffect } from 'react';

import Box from 'components/common/Box';
import LoadingSpinner from 'components/LoadingSpinner';
import Section from 'components/Mobile/Section';
import SectionHeader from './SectionHeader';

const SectionWrapper = ({
  id,
  children,
}: {
  id?: string;
  children: React.ReactNode;
}) => {
  return (
    <Box className="section-wrapper" id={id}>
      {children}
    </Box>
  );
};

const Skeletons = ({ sectionTitles }: { sectionTitles: string[] }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {sectionTitles.map((title) => (
        <Box key={title} pb="1rem">
          <Section
            // @ts-ignore
            bg="white"
          >
            <SectionHeader title={title} />
            <LoadingSpinner immediate color="lightgrey" />
          </Section>
        </Box>
      ))}
    </React.Fragment>
  );
};

SectionWrapper.Skeletons = Skeletons;

export default SectionWrapper;
