import React from 'react';
import system from '@rebass/components';

const BaseButton = system(
  {
    is: 'button',
    border: '1px solid transparent',
  },
  {
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
    transition: 'background 0.15s ease',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    verticalAlign: 'middle',
  },
  'space',
  'color',
  'width',
  'height',
  'borders',
  'borderColor',
  'borderRadius',
  'fontSize',
  'fontWeight',
  'lineHeight',
  'maxWidth'
);

const Button = system({
  extend: BaseButton,
  width: '100%',
  maxWidth: '360px',
  color: 'white',
  fontSize: '16px',
  lineHeight: 1.618,
  px: '1rem',
  py: '0.9rem',
  bg: 'mosque',
  borderRadius: '3px',
});

Button.Inverted = (props) => (
  <Button color="primary" bg="white" borderColor="mercury" {...props} />
);

Button.Round = ({ bg = 'white', ...rest }) => (
  <Button
    bg={bg}
    width="120px"
    color="initial"
    borderRadius="30px"
    borderColor={bg === 'white' ? 'mercury' : 'transparent'}
    {...rest}
  />
);

export const InvertedRound = (props) => (
  <Button
    width="initial"
    px={3}
    py={1}
    bg="concrete"
    color="primary"
    borderRadius="30px"
    borderColor="transparent"
    {...props}
  />
);

Button.InvertedRound = InvertedRound;

Button.Pure = (props) => (
  <BaseButton
    m={0}
    p={0}
    bg="transparent"
    color="charcoal"
    border="none"
    {...props}
  />
);

Button.Base = BaseButton;

['Inverted', 'Round', 'InvertedRound', 'Pure', 'Base'].forEach((tag) => {
  Button[tag].displayName = `Button.${tag}`;
});

export default Button;
