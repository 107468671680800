import { parse as parseQueryString, stringify } from 'query-string';

import browserHistory from '../browserHistory';

import type { Params } from './paths';

export default {
  push: browserHistory.push,
  replace: browserHistory.replace,
  goBack: browserHistory.goBack,
  mergeSearchKey: (paramsObj: Params) => {
    browserHistory.push({
      search: stringify({
        ...parseQueryString(browserHistory.location.search),
        ...paramsObj,
      }),
    });
  },
  removeSearchKey: (key: string) => {
    browserHistory.replace({
      search: stringify({
        ...parseQueryString(browserHistory.location.search),
        [key]: undefined,
      }),
    });
  },
};
