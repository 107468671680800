import system from '@rebass/components';

const HorizontalDivider = system(
  {
    is: 'hr',
    borderColor: 'concrete',
  },
  {
    height: 0,
    width: '100%',
    border: 0,
    borderTop: '1px solid',
  },
  'space',
  'borderColor',
  'width'
);

export default HorizontalDivider;
