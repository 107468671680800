import React from 'react';

import type { PeriodType } from 'libs/period';

import { updateDateRangeQueries } from 'hooks/useDateRange';

import { Day } from 'libs/period';

import { formatCurrency } from 'utils/number';
import colors from 'constants/colors';

export interface PeriodResult {
  period: PeriodType;
  result: any;
}

export function getResults({
  data,
  key,
  periods,
}: {
  data: any;
  key: string;
  periods: PeriodType[];
}): PeriodResult[] {
  return periods.map((period, index) => ({
    period,
    result: data.business[`${key}${index}`],
  }));
}

export function getAmount(dataArray: any, index: number, key = 'amount') {
  return dataArray?.[index].result?.[key] || 0;
}

export function browsingToday(period: PeriodType) {
  return period instanceof Day && period.isToday();
}

export interface AmountByDate {
  date: string;
  period: PeriodType;
  amount: number;
}

export function amountsByDate(
  results: PeriodResult[],
  type: string
): AmountByDate[] {
  return results
    .map((data: PeriodResult) => ({
      date: data.period.toLabel(),
      period: data.period,
      amount: data.result[type],
    }))
    .reverse();
}

export function onClickGraph(target: HTMLElement, period: PeriodType) {
  const scrollTargetId = target?.closest('.section-wrapper')?.id;

  if (scrollTargetId) {
    updateDateRangeQueries({
      other: { scroll: scrollTargetId },
      ...period.toDateRange(),
    });
  }
}

export const CustomAxisLabel = ({
  x,
  y,
  payload,
  amounts,
}: {
  x: number;
  y: number;
  payload: { value: string };
  amounts: AmountByDate[];
}) => {
  const period = amounts.find(
    (amount: AmountByDate) => amount.date === payload.value
  )!.period;

  return (
    <text
      x={x}
      y={y}
      height={30}
      dy="8px"
      stroke="none"
      fill="#666"
      fontSize="11px"
      textAnchor="middle"
      onClick={(event: any) => onClickGraph(event.target, period)}
    >
      {payload.value}
    </text>
  );
};

export const CustomBarLabel = ({
  x,
  y,
  width,
  value,
}: {
  x: number;
  y: number;
  width: number;
  value: number;
}) => {
  if (value === 0) {
    return null;
  }

  return (
    <text
      x={x + width / 2}
      y={y}
      fill={colors.primary}
      textAnchor="middle"
      dy={-6}
      fontSize="10px"
    >{`${formatCurrency(value)}`}</text>
  );
};

export const CustomPieLabel = ({
  x,
  y,
  cx,
  label,
  percent,
}: {
  x: number;
  y: number;
  cx: number;
  label: string;
  percent: number;
}) => {
  return (
    <text
      x={x}
      y={y}
      fill={colors.darkgrey}
      textAnchor={x > cx ? 'start' : 'end'}
      alignmentBaseline="middle"
      fontSize="11px"
      fontWeight="bold"
    >
      {`${label} ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
