import moment from 'moment';
import type { Moment, MomentInput } from 'moment';

import type { DateRange } from 'types';
import { MODE } from 'types';

export type PeriodType = Day | Week | Month | Range;

abstract class Period {
  public date: Moment;

  constructor(dateInput: MomentInput) {
    this.date = moment(dateInput);
  }

  isSameOrAfter(otherDate: Moment) {
    return this.date.isSameOrAfter(otherDate, 'day');
  }

  isAfter(otherDate: Moment) {
    return this.date.isAfter(otherDate, 'day');
  }

  format(inputString: string) {
    return `${this.date.format(inputString)}`;
  }

  toDateRangeInput() {
    const { startDate: start, endDate: end } = this.toDateRange();

    return { start, end };
  }

  abstract prev(): Period;
  abstract toDateRange(): DateRange;
  abstract toText(): string;
}

export class Day extends Period {
  isToday() {
    return this.date.isSame(moment(), 'day');
  }

  prev() {
    return new Day(moment(this.date).subtract(1, 'day'));
  }

  toText() {
    return this.format('M월 D일 (dd)');
  }

  toLabel() {
    return this.format('M.D');
  }

  toLegend() {
    return this.format('M월D일');
  }

  toDateRange() {
    return {
      mode: MODE.DAY,
      startDate: moment(this.date).startOf('day'),
      endDate: moment(this.date).endOf('day'),
    };
  }
}

export class Week extends Period {
  constructor(dateInput: MomentInput) {
    super(moment(dateInput).startOf('isoWeek'));
  }

  static fromDateRangeVariable(rangeStr: string) {
    const matched = rangeStr.match(/^(\d{4})-(\d{2})-W(\d)$/);

    if (!matched) {
      return null;
    }

    const firstDayOfMonth = moment(`${matched[1]}-${matched[2]}-01`);
    const weekNum =
      firstDayOfMonth.isoWeekday() < 5
        ? parseInt(matched[3]) - 1
        : parseInt(matched[3]);

    return new Week(firstDayOfMonth.startOf('isoWeek').add(weekNum, 'week'));
  }

  prev() {
    return new Week(moment(this.date).subtract(1, 'week').startOf('isoWeek'));
  }

  toText({ includeYear = false } = {}) {
    const thursday = moment(this.date).isoWeekday(4);
    let formatString = `M월 ${Math.ceil(thursday.date() / 7)}주차`;

    if (includeYear && moment().year() !== thursday.year()) {
      formatString = `YYYY년 ${formatString}`;
    }
    return thursday.format(formatString);
  }

  toLabel() {
    return moment(this.date).endOf('isoWeek').format(`~M.D`);
  }

  toLegend() {
    const thursday = moment(this.date).isoWeekday(4);
    return thursday.format(`M월${Math.ceil(thursday.date() / 7)}주차`);
  }

  toIsoString() {
    const thursday = moment(this.date).isoWeekday(4);
    return `${thursday.format('YYYY-MM')}-W${Math.ceil(thursday.date() / 7)}`;
  }

  toDateRange() {
    return {
      mode: MODE.WEEK,
      startDate: moment(this.date).startOf('isoWeek'),
      endDate: moment(this.date).endOf('isoWeek'),
    };
  }
}

export class Month extends Period {
  constructor(dateInput: MomentInput) {
    super(moment(dateInput).startOf('month'));
  }

  static fromGraphQLType(typeStr: string) {
    return new Month(typeStr + '-01');
  }

  isSame(otherDate: Moment) {
    return this.date.isSame(otherDate, 'month');
  }

  prev() {
    return new Month(moment(this.date).subtract(1, 'month'));
  }

  toText() {
    return this.format(`M월`);
  }

  toLabel() {
    return this.format(`M월`);
  }

  toLegend() {
    return this.format(`M월`);
  }

  toGraphQLType() {
    return this.format('YYYY-MM');
  }

  toDateRange() {
    return {
      mode: MODE.MONTH,
      startDate: moment(this.date).startOf('month'),
      endDate: moment(this.date).endOf('month'),
    };
  }
}

export class Quarter {
  date: Moment;
  year: number;
  quarterNumber: number;

  constructor(date: MomentInput) {
    this.date = moment(date).startOf('quarter');
    this.year = this.date.year();
    this.quarterNumber = this.date.quarter();
  }

  static fromDateRangeVariable(rangeStr: string) {
    const matched = rangeStr.match(/^(\d{4})-Q(\d{1})$/);

    if (!matched) {
      return null;
    }

    const [, year, quarter] = matched;

    return new Quarter(
      moment().year(parseInt(year)).quarter(parseInt(quarter))
    );
  }

  prev() {
    return new Quarter(moment(this.date).subtract(1, 'quarter'));
  }

  toText(format = 'YYYY년 Q분기') {
    return this.date.format(format);
  }

  toIsoString() {
    return this.date.format('YYYY-\\QQ');
  }
}

export class Range extends Period {
  endDate: Moment;

  constructor(startDate: MomentInput, endDate: MomentInput) {
    super(startDate);
    this.endDate = moment(endDate);
  }

  prev() {
    const durationDays = moment.duration(this.endDate.diff(this.date)).days();

    const newStartDate = moment(this.date).subtract(durationDays + 1, 'days');
    const newEndDate = moment(this.endDate).subtract(durationDays + 1, 'days');

    return new Range(newStartDate, newEndDate);
  }

  format(inputString: string) {
    return `${this.date.format(inputString)}..${this.endDate.format(
      inputString
    )}`;
  }

  toText(format = 'M월 D일') {
    const start = this.date.format(format);

    if (this.date.isSame(this.endDate, 'day')) {
      return start;
    } else if (this.date.isSame(this.endDate, 'month')) {
      const endFormat = format.substring(format.indexOf('D'));
      return `${start} - ${this.endDate.format(endFormat)}`;
    }

    return `${start} - ${this.endDate.format(format)}`;
  }

  toLabel() {
    return `~${this.endDate.format('M.D')}`;
  }

  toLegend() {
    return this.toText('M월D일');
  }

  toDateRange() {
    return {
      mode: MODE.CUSTOM,
      startDate: moment(this.date).startOf('day'),
      endDate: moment(this.endDate).endOf('day'),
    };
  }
}
