import system from '@rebass/components';

const Box = system(
  (props) => props.css,
  'space',
  'color',
  'width',
  'minWidth',
  'maxWidth',
  'fontSize',
  'flex',
  'order',
  'alignSelf',
  'textAlign',
  'display',
  'position'
);

Box.displayName = 'Box';

export default Box;
