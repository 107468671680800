import moment from 'moment';
import type { Moment } from 'moment';

import type { Credential, Credentials } from 'types';
import { isAggregated } from 'libs/BusinessCredentials';

export const SCRAPING_STATUSES = {
  SCRAPING: '수집 중',
  YET: '수집 전',
  INVALID: '수집 불가',
  COMPLETED: '완료',
} as const;

export type ScrapingStatusValue = typeof SCRAPING_STATUSES[keyof typeof SCRAPING_STATUSES];

const SCRAPE_AT = {
  cards: 5,
  invoices: 7,
  deliveryApps: 7,
  cashReceipts: 15,
} as const;

type ScrapeKind = keyof typeof SCRAPE_AT | 'cardSales';

export function isBeforeSchedule(
  dateMoment: Moment,
  kind: ScrapeKind,
  missingDates: Array<string> = []
) {
  const yesterday = moment().subtract(1, 'day');

  if (!dateMoment.isSame(yesterday, 'day')) {
    return false;
  }

  if (kind === 'cardSales') {
    return missingDates.includes(yesterday.format('YYYY-MM-DD'));
  }

  return moment().hour() < SCRAPE_AT[kind];
}

export function scrapingStatus(
  credential?: Credential | Credentials,
  yet: boolean = false
): ScrapingStatusValue | null {
  if (isAggregated(credential)) {
    if (credential.areEmpty) {
      return null;
    } else if (credential.allAreValidating) {
      return SCRAPING_STATUSES.SCRAPING;
    } else if (credential.allAreInvalid) {
      return SCRAPING_STATUSES.INVALID;
    } else if (yet) {
      return SCRAPING_STATUSES.YET;
    }

    return SCRAPING_STATUSES.COMPLETED;
  }

  if (!credential) {
    return null;
  } else if (credential.isValidating) {
    return SCRAPING_STATUSES.SCRAPING;
  } else if (credential.isInvalid) {
    return SCRAPING_STATUSES.INVALID;
  } else if (yet) {
    return SCRAPING_STATUSES.YET;
  }

  return SCRAPING_STATUSES.COMPLETED;
}
