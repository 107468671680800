import React from 'react';

import Text from 'components/common/Text';

import { formatWithCommas } from 'utils/number';

const FormatNumber = (props) => {
  const value = props.signed ? props.value : Math.abs(props.value);
  const formatted = formatWithCommas(value);

  if (props.children) {
    return props.children(formatted);
  } else {
    return <span>{formatted}</span>;
  }
};

FormatNumber.Won = ({
  value = 0,
  hasLineThrough = false,
  prefix = '',
  ...rest
}) => (
  <FormatNumber signed value={value}>
    {(formatted) => {
      const Tag = hasLineThrough ? Text.LineThrough : Text;

      return (
        <Tag as="span" {...rest}>
          {prefix}
          {formatted}원
        </Tag>
      );
    }}
  </FormatNumber>
);

FormatNumber.Won.displayName = 'FormatNumber.Won';

export default FormatNumber;
