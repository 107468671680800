import gql from 'graphql-tag.macro';

export default gql`
  mutation loginWithKakao($accessToken: String!, $anonymousId: String, $inviterCode: String, $discountCode: String) {
    loginWithKakao(
      input: {
        accessToken: $accessToken
        anonymousId: $anonymousId
        inviterCode: $inviterCode
        discountCode: $discountCode
      }
    ) {
      jwt
      errors {
        field
        messages
      }
    }
  }
`;
