import get from 'lodash/get';

export function hasErrorWithCode(error, code) {
  if (!error) {
    return false;
  }

  const errors = Array.isArray(error) ? error : error.graphQLErrors;
  return errors.some((e) => e.extensions && e.extensions.code === code);
}

export function extractErrors(data, path = 'response') {
  if (!data) {
    return {};
  }

  const dataPath = get(data, path);
  if (!dataPath) {
    throw Error(
      `extractErrors: path "${path}" is not included in ${JSON.stringify(
        data
      )}.`
    );
  }

  if (!dataPath.errors) {
    return {};
  }

  return dataPath.errors.reduce((acc, error) => {
    acc[error.field] = error.messages[0];
    return acc;
  }, {});
}

export function extractEdgeNodes(connection) {
  if (!connection || !connection.edges) {
    return [];
  }

  return connection.edges
    .map(({ node }) => node)
    .filter((node) => node !== null);
}
