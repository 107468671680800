export type Params = { [key: string]: any };

export default {
  root() {
    return '/';
  },
  login: {
    index(query = '') {
      return `/login${query}`;
    },
    account() {
      return '/login/account';
    },
  },
  logout() {
    return '/logout';
  },
  account: {
    retrieve() {
      return '/account/retrieve';
    },
  },
  password: {
    reset() {
      return '/password/reset';
    },
  },
};
