import React from 'react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

import Icon from 'components/Icon';
import Button from 'components/advanced/Button';
import Flex from 'components/common/Flex';
import Box from 'components/common/Box';
import Text from 'components/common/Text';
import KakaoLogin from './KakaoLogin';

import withKakao from 'hocs/withKakao';

import shinhancardLogo from 'assets/shinhan-myshop/shinhancard-logo.png';
import cross from 'assets/shinhan-myshop/cross.svg';
import cashnoteLogo from 'assets/logo-text.png';
import illustration from 'assets/shinhan-myshop/illustration.jpg';

import paths from 'paths';

const SelectLogin = ({ isKakaoLoaded }: { isKakaoLoaded: boolean }) => {
  return (
    <Box maxWidth="500px" mx="auto" textAlign="center" pt="55px" px="28px">
      <Flex alignItems="center" justifyContent="center" mb="32px">
        <img src={shinhancardLogo} alt="신한카드" height="18px" />
        <Box mx="10px">
          <img src={cross} alt="x" />
        </Box>
        <img src={cashnoteLogo} alt="캐시노트" height="18px" />
      </Flex>
      <Text fontSize="2rem" color="black" fontWeight="bold" mb="28px">
        사장님의 주간 매출을 <br />
        간편히 조회하세요.
      </Text>
      <Box mb="32px">
        <img src={illustration} alt="신한은행 x 캐시노트" width="170px" />
      </Box>

      <Box
        css={{
          button: {
            borderRadius: '50px',
          },
        }}
      >
        <KakaoLogin hidden={!isKakaoLoaded} />
        <Link to={paths.login.account()}>
          <Button.Inverted color="sealBrown" mt="1rem">
            휴대전화번호 로그인
          </Button.Inverted>
        </Link>
      </Box>

      <Box
        mt="24px"
        onClick={() =>
          toast(
            '플레이스토어 또는 앱스토어에서 캐시노트를 설치하신 다음 새로 가입해주세요.'
          )
        }
        className="cta"
      >
        캐시노트 회원가입 <Icon.Right />
      </Box>
    </Box>
  );
};

export default withKakao(SelectLogin);
