import axios from 'axios';
import camelize from 'camelize';
import snakeize from 'snakeize';
import storage from 'local-storage-fallback';

const defaultTransformRequest = axios.defaults.transformRequest ?? [];
const defaultTransformResponse = axios.defaults.transformResponse ?? [];
const transformResponse = Array.isArray(defaultTransformResponse)
  ? defaultTransformResponse
  : [defaultTransformResponse];

const api = axios.create({
  baseURL: process.env.REACT_APP_REST_API_ENDPOINT,
  transformRequest: [snakeize].concat(...defaultTransformRequest),
  transformResponse: transformResponse.concat(camelize),
});

const getToken = () => ({
  Authorization: `Bearer ${storage.getItem('token')}`,
});

export default {
  auth: {
    confirmation: {
      request(params: { phoneNumber: string; reauthenticate?: boolean }) {
        return api.post('/auth/confirmation', params);
      },
      verify(params: { phoneNumber: string; token: string }) {
        return api.get(
          `/auth/confirmation?phone_number=${params.phoneNumber}&token=${params.token}`
        );
      },
    },
    refresh() {
      return api.post('/auth/refresh', {}, { headers: getToken() });
    },
    sudo(password: string) {
      return api.post('/auth/sudo', { password }, { headers: getToken() });
    },
  },
  account: {
    retrieve(params: { registrationNumber: string; phone: string }) {
      return api.post('/account/retrieve', params);
    },
  },
  naver: {
    search(query: string, page = 1) {
      return api.get(
        `/naver_places/search?query=${encodeURIComponent(query)}&page=${page}`,
        {
          headers: getToken(),
        }
      );
    },
  },
};
