import React from 'react';

import Flex from 'components/common/Flex';
import Text from 'components/common/Text';
import Icon from 'components/Icon';

interface NoticeProps {
  compact?: boolean;
  iconName: string;
  children: React.ReactNode;
}

const Notice = ({ compact, iconName, children, ...rest }: NoticeProps) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    py={compact ? 3 : 4}
    bg="white"
    color="tin"
    {...rest}
  >
    <Icon mb={2} size="3.5rem" name={iconName} color="mercury" />
    <Text textAlign="center">{children}</Text>
  </Flex>
);

interface NoticeVariantProps {
  compact?: boolean;
}

interface ScrapingProps extends NoticeVariantProps {
  action?: string;
  notifyWhenFinished?: boolean;
}

Notice.Scraping = ({
  action = '거래내역 업데이트',
  notifyWhenFinished = true,
  ...props
}: ScrapingProps) => (
  <Notice iconName="clock-o" {...props}>
    현재 {action} 중입니다.
    {notifyWhenFinished && <div>완료되면 알림메시지로 안내해드립니다.</div>}
  </Notice>
);

Notice.BeforeScraping = (props: NoticeVariantProps) => (
  <Notice iconName="clock-o" {...props}>
    아직 거래내역이 수집되지 않았습니다.
  </Notice>
);

Notice.InvalidRange = (props: NoticeVariantProps) => (
  <Notice iconName="clock-o" {...props}>
    지원하지 않는 기간입니다.
  </Notice>
);

Notice.Empty = (props: NoticeVariantProps) => (
  <Notice iconName="times-circle-o" {...props}>
    내역이 없습니다.
  </Notice>
);

export default Notice;
