import type { Moment } from 'moment';
import { QueryResult as ApolloQueryResult } from '@apollo/react-common';

import type BusinessCredentials from 'libs/BusinessCredentials';
import type {
  Credential as _Credential,
  CredentialWithStatusProps,
  CredentialsWithStatusProps,
} from 'libs/BusinessCredentials';

export type { BusinessCredentials };
export type RawCredential = _Credential;
export type Credential = CredentialWithStatusProps;
export type Credentials = CredentialsWithStatusProps;

export const UserRoles = ['OWNER', 'MANAGER'] as const;
export const UserRepresentativeRoles = [
  'VERIFIED_OWNER',
  'STAKEHOLDER',
  'UNKNOWN',
];

export interface Business {
  id: string;
  name: string;
  registrationNumber: string;
  subscription: {
    paid: boolean;
  } | null;
  role: typeof UserRoles[number];
  representativeRole: typeof UserRepresentativeRoles[number];
  hasSalesInvoices: boolean;
}

export interface Member {
  id: string;
  name: string | null;
  phoneNumber: string;
  role: typeof UserRoles[number];
  representativeRole: typeof UserRepresentativeRoles[number];
  isMe: boolean;
  invitationPending: boolean;

  displayPhoneNumber: string;
  isOwner: boolean;
}

export const MODE = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  CUSTOM: 'CUSTOM',
} as const;

export type ModeType = keyof typeof MODE;

export interface DateRange {
  mode: ModeType;
  startDate: Moment;
  endDate: Moment;
  maxDate?: Moment;
}

export interface BankAccount {
  id: string;
  bankName: string;
  accountNumber: string;
  credential: RawCredential;
  transactions: {
    totalBalance: number;
  };
}

export interface Card {
  id: string;
  name: string;
  credential: RawCredential;
  purchases: {
    totalAmount: number;
    totalCount: number;
  };
}

export interface UtilityBillCredential extends Credential {
  id: string;
  name: string;
  number: string;
}

export interface HometaxCredential extends Credential {
  id: string;
  type?: 'PERSONAL';
}

export interface QueryResult extends ApolloQueryResult {
  fetchingMore: boolean;
  refetching: boolean;
}
