import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from 'ducks/auth';
import paths from 'paths';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    paths.replace(paths.login.index());
  });

  return null;
};

export default Logout;
