import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactHeight } from 'react-height';

import Border from 'components/common/Border';
import Flex from 'components/common/Flex';
import Icon from 'components/Icon';

const ExpandButton = ({ onClick, children, ...rest }) => (
  <Border borderTop={1} borderColor="concrete" mt="1rem" pt="0.8rem" {...rest}>
    <Flex
      alignItems="center"
      justifyContent="center"
      color="tin"
      onClick={onClick}
    >
      {children}
      <Icon name="chevron-down" ml={1} size="0.75rem" color="lightgrey" />
    </Flex>
  </Border>
);

const propTypes = {
  maxHeightPx: PropTypes.number.isRequired,
  initialExpanded: PropTypes.bool,
  buttonText: PropTypes.string,
  expandButton: PropTypes.elementType,
  onExpand: PropTypes.func,
};

const defaultProps = {
  initialExpanded: false,
  buttonText: '더 보기',
  expandButton: ExpandButton,
  onExpand: () => {},
};

const Expandable = ({
  maxHeightPx,
  initialExpanded,
  buttonText,
  onExpand,
  expandButton,
  children,
}) => {
  const [expanded, setExpanded] = useState(initialExpanded);
  const [expandVisible, setExpandVisible] = useState(false);

  const handleExpand = () => {
    setExpanded(true);
    setExpandVisible(false);
    onExpand();
  };

  const Button = expandButton;

  return expanded ? (
    children
  ) : (
    <React.Fragment>
      <ReactHeight
        getElementHeight={(element) => element.scrollHeight}
        onHeightReady={(height) =>
          height > maxHeightPx ? setExpandVisible(true) : handleExpand()
        }
        style={{ maxHeight: `${maxHeightPx}px`, overflow: 'hidden' }}
      >
        {children}
      </ReactHeight>
      {expandVisible && <Button onClick={handleExpand}>{buttonText}</Button>}
    </React.Fragment>
  );
};

Expandable.propTypes = propTypes;
Expandable.defaultProps = defaultProps;

export default Expandable;
