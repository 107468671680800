import React from 'react';

import Box from 'components/common/Box';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';
import Icon from 'components/Icon';

const InfoBox = ({ bg = 'concrete', ...rest }) => (
  <Box fontSize="0.85rem" p={2} bg={bg} {...rest} />
);

InfoBox.Item = ({
  iconColor = 'primaryLight',
  color = 'charcoal',
  fontSize = 'inherit',
  children,
  ...rest
}) => (
  <Flex
    alignItems="baseline"
    css={{
      ':not(:last-child)': {
        marginBottom: '8px',
      },
    }}
    fontSize={fontSize}
    {...rest}
  >
    <Icon name="info-circle" color={iconColor} mr={1} />
    <Text color={color} wb="break-all">
      {children}
    </Text>
  </Flex>
);
InfoBox.Item.displayName = 'InfoBox.Item';

export default InfoBox;
