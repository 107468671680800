import React, { Component } from 'react';
import { Formik } from 'formik';
import toast from 'react-hot-toast';

import Container from 'components/common/Container';
import Text from 'components/common/Text';
import Form from 'components/Form';
import Icon from 'components/Icon';

import api from 'api';
import paths from 'paths';

import t from 'i18n';

import { isValidPhoneNumber } from 'utils/phone';
import { isValidRegistrationNumber } from 'utils/registrationNumber';

export class RetrieveForm extends Component {
  validateForm = (values) => {
    const errors = {};
    if (
      values.registrationNumber &&
      !isValidRegistrationNumber(values.registrationNumber)
    ) {
      errors.registrationNumber = t('errors.messages.invalid');
    }
    if (!values.phone) {
      errors.phone = t('errors.messages.required');
    } else if (!isValidPhoneNumber(values.phone)) {
      errors.phone = t('errors.messages.invalid');
    }
    return errors;
  };

  handleFormSubmit = (values, { setSubmitting }) => {
    api.account
      .retrieve({
        registrationNumber: values.registrationNumber,
        phone: values.phone,
      })
      .then(() => {
        toast(
          '아이디가 문자메시지로 발송되었습니다.\n로그인 화면으로 이동합니다.'
        );
        paths.push(paths.login.index());
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          toast('입력하신 정보에 해당하는 아이디가 없습니다.');
        } else {
          toast(t('errors.messages.generic'));
        }
        setSubmitting(false);
      });
  };

  render() {
    return (
      <Formik
        initialValues={{
          registrationNumber: '',
          phone: '',
        }}
        validate={this.validateForm}
        onSubmit={this.handleFormSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group
              id="phone"
              label="휴대전화번호"
              error={touched.phone && errors.phone}
            >
              <Form.Phone
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
              />
            </Form.Group>
            <Form.Group
              id="registrationNumber"
              label="사업자등록번호"
              error={touched.registrationNumber && errors.registrationNumber}
            >
              <Form.RegistrationNumber
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.registrationNumber}
              />
            </Form.Group>
            <Form.Submit disabled={isSubmitting}>아이디 찾기</Form.Submit>
            <Container maxWidth="256px" my={3}>
              <Text textAlign="center" color="tin" fontSize="0.9rem">
                <Icon name="info-circle" mr={1} />
                캐시노트에 등록된 사업장이 없다면 사업자등록번호 란은
                비워두세요.
              </Text>
            </Container>
          </Form>
        )}
      />
    );
  }
}

export default RetrieveForm;
