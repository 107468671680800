import system from '@rebass/components';

import Box from 'components/common/Box';

const Flex = system(
  {
    extend: Box,
  },
  {
    display: 'flex',
  },
  'flexWrap',
  'flexDirection',
  'alignItems',
  'justifyContent'
);

Flex.displayName = 'Flex';

export default Flex;
