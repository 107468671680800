import gql from 'graphql-tag.macro';

export default gql`
  mutation resetPassword($registrationNumber: String, $phoneNumber: String!) {
    resetPassword(input: { registrationNumber: $registrationNumber, phoneNumber: $phoneNumber }) {
      errors {
        field
        messages
      }
    }
  }
`;
