import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import type { DateRange } from 'types';

import FixedHeight from 'components/FixedHeight';
import DateRangeSelector from 'components/DateRangeSelector';
import Box from 'components/common/Box';
import InfoBox from 'components/InfoBox';

import LogoutButton from 'pages/Auth/Logout/LogoutButton';

import SectionWrapper from 'components/Finance/Reports/shared/SectionWrapper';
import CardSalesTransactionsSection from 'components/Finance/Reports/CardSalesTransactionsSection';
import useDateRange, { dateRangePeriodsAndVariables } from 'hooks/useDateRange';
import GET_SALES_SUMMARY from 'graphql/queries/getSalesSummary.graphql';

const Body = ({
  dateRange,
  businessId,
}: {
  dateRange: DateRange;
  businessId: string;
}) => {
  const [periods, variables] = dateRangePeriodsAndVariables(dateRange);
  const { loading, data } = useQuery(GET_SALES_SUMMARY, {
    variables: { businessId, ...variables },
  });

  if (loading) {
    return <SectionWrapper.Skeletons sectionTitles={['카드매출']} />;
  }

  return (
    <>
      <SectionWrapper id="card-sales">
        <CardSalesTransactionsSection
          data={data}
          periods={periods}
          sectionTitle="카드매출"
          businessId={businessId}
        />
      </SectionWrapper>
      <Box mx="1.3rem" px="12px" py="14px" fontSize="12px" bg="ghost">
        <InfoBox.Item color="tin" iconColor="lightgrey">
          본 서비스는 <b>캐시노트</b>를 통해 제공됩니다.
        </InfoBox.Item>
      </Box>
      <LogoutButton />
    </>
  );
};

const Report = ({ businessId }: { businessId: string }) => {
  const dateRange = useDateRange();

  return (
    <>
      <FixedHeight bg="white">
        <DateRangeSelector {...dateRange} modeFixed />
      </FixedHeight>
      <Body businessId={businessId} dateRange={dateRange} />
    </>
  );
};

export default Report;
