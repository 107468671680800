import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Mutation } from 'react-apollo';
import toast from 'react-hot-toast';

import Box from 'components/common/Box';
import LoginForm from './LoginForm';
import PasswordlessNotice from './PasswordlessNotice';

import { loginSuccess } from 'ducks/auth';
import loginMutation from 'graphql/mutations/login.graphql';

import { hasErrorWithCode, extractErrors } from 'utils/graphql';
import paths from 'paths';
import t from 'i18n';

const LoginWithPhone = () => {
  const dispatch = useDispatch();
  const [showPasswordlessNotice, setShowPasswordlessNotice] = useState(false);

  const handleSubmit = (mutate, { login, password }) => {
    return mutate({
      variables: {
        login: login.trim(),
        password: password.trim(),
      },
    })
      .then(({ data: { login: payload } }) => {
        if (payload.jwt) {
          dispatch(loginSuccess(payload.jwt));
        } else {
          toast(
            '아이디/휴대전화번호나 비밀번호가 틀립니다.\n확인 후 정확하게 다시 입력해주세요.'
          );
        }
      })
      .catch((error) => {
        if (hasErrorWithCode(error, 'no_password')) {
          setShowPasswordlessNotice(true);
        } else {
          toast(t('errors.messages.generic'));
        }
      });
  };

  return (
    <section>
      <header>
        <h2>로그인</h2>
      </header>
      {showPasswordlessNotice && (
        <PasswordlessNotice onClose={() => setShowPasswordlessNotice(false)} />
      )}
      <Mutation mutation={loginMutation}>
        {(mutate, { data }) => (
          <LoginForm
            onSubmit={(values) => handleSubmit(mutate, values)}
            errors={extractErrors(data, 'login')}
          />
        )}
      </Mutation>
      <Box mt="2rem" textAlign="center">
        <Box mb="2rem">
          <Link to={paths.account.retrieve()}>아이디 찾기</Link>
          <Link to={paths.password.reset()} style={{ marginLeft: '1rem' }}>
            비밀번호 재발급
          </Link>
        </Box>
      </Box>
    </section>
  );
};

export default LoginWithPhone;
