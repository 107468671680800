import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import moment from 'moment';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { Toaster } from 'react-hot-toast';

import apolloClient from 'graphql/apolloClient';
import browserHistory from 'browserHistory';
import configureStore from 'store/configureStore';

import Root from 'Root';
import ErrorBoundary from 'ErrorBoundary';
import ThemeProvider from 'ThemeProvider';

import 'moment/locale/ko';
import './SpoqaHanSans-kr.css';
import './index.css';

moment.locale('ko-KR');

if (window.FontFace?.prototype.hasOwnProperty('display')) {
  document.body.classList.add('spoqa-han-sans-loaded');
} else {
  import('fontfaceobserver').then(({ default: FontFaceObserver }) => {
    Promise.all(
      [400, 700].map((weight) =>
        new FontFaceObserver('Spoqa Han Sans', { weight }).load(null, 1000 * 15)
      )
    ).then(() => {
      document.body.classList.add('spoqa-han-sans-loaded');
    });
  });
}

const store = configureStore({});

ReactDOM.render(
  <ErrorBoundary>
    <ApolloHooksProvider client={apolloClient}>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <Router history={browserHistory}>
            <ThemeProvider>
              <Root />
              <Toaster toastOptions={{ duration: 3000 }} />
            </ThemeProvider>
          </Router>
        </Provider>
      </ApolloProvider>
    </ApolloHooksProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
