import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';

import useAuthToken from 'hooks/useAuthToken';

import { redirectAfterLogin, refresh } from 'ducks/auth';
import paths from 'paths';

const PrivateRoute = (props: any) => {
  const dispatch = useDispatch();
  const loggedIn = !!useAuthToken();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (loggedIn) {
      dispatch(refresh());
    } else {
      dispatch(redirectAfterLogin(`${pathname}${search}`));
      paths.replace(paths.login.index(search));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return loggedIn ? <Route {...props} /> : null;
};

export default PrivateRoute;
