function hasFinal(string: string) {
  const lastCharCode = string.charCodeAt(string.length - 1);
  return (lastCharCode - 0xac00) % 28 > 0;
}

type Candidates = '을를' | '은는' | '이가' | '과와';

export function withJosa(target: string, candidates: Candidates): string {
  const josa = hasFinal(target) ? candidates[0] : candidates[1];
  return target + josa;
}
