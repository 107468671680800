import storage from 'local-storage-fallback';

const STORAGE_ITEMS = {
  MYSHOP_REGISTRATION_NUMBER: 'myshopRegistrationNumber',
} as const;

type StorageItemKey = keyof typeof STORAGE_ITEMS;

function getItemSafely(key: StorageItemKey): any | null {
  const storageItem = storage.getItem(STORAGE_ITEMS[key]);
  if (storageItem === null) {
    return null;
  }

  try {
    return JSON.parse(storageItem);
  } catch (e) {
    return null;
  }
}

function useStorage(
  key: StorageItemKey
): [any | null, (value: any) => any | null] {
  const setItem = (value: any) => {
    const lastValue = getItemSafely(key);
    storage.setItem(STORAGE_ITEMS[key], JSON.stringify(value));

    return lastValue;
  };

  return [getItemSafely(key), setItem];
}

export default useStorage;
