import system from '@rebass/components';

const Border = system(
  {},
  'borders',
  'borderRadius',
  'borderColor',
  'space',
  'width',
  'color',
  'flex'
);

Border.displayName = 'Border';

export default Border;
