import { useQuery } from '@apollo/react-hooks';

import GET_BUSINESSES from 'graphql/queries/getBusinesses.graphql';

import { extractEdgeNodes } from 'utils/graphql';

interface BusinessListItem {
  id: string;
  name: string;
  registrationNumber: string;
}

interface UseBusinessesType {
  loading: boolean;
  businesses: Array<BusinessListItem>;
}

function useBusinesses(): UseBusinessesType {
  const { data, loading } = useQuery(GET_BUSINESSES);

  return {
    loading,
    businesses: extractEdgeNodes(data?.businesses),
  };
}

export default useBusinesses;
