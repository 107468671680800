import { useCurrentBusinessId } from 'hooks/useCurrentBusiness';

export type Path = string | ((businessId: string) => string);

function usePath(path: Path) {
  const businessId = useCurrentBusinessId();

  return typeof path === 'function' ? path(businessId) : path;
}

export default usePath;
