import React from 'react';

import Error from 'pages/Error';
import Report from './Report';

import useBusinesses from 'hooks/useBusinesses';
import useStorage from 'hooks/useStorage';
import useCredentials from 'hooks/useCredentials';

const Body = ({ businessId }: { businessId: string }) => {
  const credentials = useCredentials(businessId);

  if (!credentials) {
    return null;
  }

  if (!credentials.crefia || credentials.crefia.isInvalid) {
    return <Error kind="CREFIA_NOT_VALID" />;
  }

  return <Report businessId={businessId} />;
};

const WeeklyReports = () => {
  const { loading, businesses } = useBusinesses();
  const [registrationNumber] = useStorage('MYSHOP_REGISTRATION_NUMBER');

  if (loading) {
    return null;
  }

  const matchingBusiness = businesses.find(
    (business) => business.registrationNumber === registrationNumber
  );

  if (!matchingBusiness) {
    return <Error kind="BUSINESS_NOT_FOUND" />;
  }

  return <Body businessId={matchingBusiness.id} />;
};

export default WeeklyReports;
