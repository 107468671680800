import React from 'react';

import Text from 'components/common/Text';
import Box from 'components/common/Box';
import Icon from 'components/Icon';
import { SectionHeaderDescription } from 'components/Mobile/Section';

const SectionDescription = ({ children }: { children: React.ReactNode }) => (
  // @ts-ignore
  <SectionHeaderDescription fontSize="0.8rem" mt="1rem" color="darkgrey">
    {children}
  </SectionHeaderDescription>
);

SectionDescription.Info = ({
  children,
  iconColor = 'inherit',
  ...rest
}: {
  children: React.ReactNode;
  iconColor?: string;
  color?: string;
}) => (
  <Text wb="break-all" {...rest}>
    <Icon name="info-circle" color={iconColor} mr={1} />
    {children}
  </Text>
);

SectionDescription.Invalidated = ({ label }: { label: string }) => (
  <Box color="maroon">
    <Icon name="info-circle" mr={1} />
    {label} 수집이 불가합니다.
  </Box>
);

SectionDescription.Scraping = () => (
  <SectionDescription.Info>
    현재 거래내역 업데이트 중입니다. 완료되면 알림메시지로 안내해드립니다.
  </SectionDescription.Info>
);

SectionDescription.Important = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <SectionDescription.Info iconColor="primaryLight" color="charcoal">
    {children}
  </SectionDescription.Info>
);

export default SectionDescription;
