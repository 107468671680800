import styled from 'styled-components/macro';
import system from '@rebass/components';
import { style } from 'styled-system';

import colors from 'constants/colors';

const wordBreak = style({
  prop: 'wb',
  cssProperty: 'wordBreak',
});

export const Text = system(
  {
    m: 0,
  },
  'space',
  'color',
  'fontFamily',
  'fontSize',
  'fontWeight',
  'textAlign',
  'lineHeight',
  'opacity',
  wordBreak
);

Text.displayName = 'Text';

Text.LineThrough = styled(Text)`
  text-decoration: line-through;
`;

Text.Underline = styled(Text)`
  text-decoration: underline;
`;

Text.WhiteSpacePreserved = styled(Text)`
  white-space: pre-wrap;
  word-break: break-word;
`;

Text.StyledUnderline = styled(Text)`
  display: inline-block;
  line-height: 1.2;

  &::after {
    display: block;
    content: '';
    border-bottom: ${(props) => props.linewidth || '1px'} solid
      ${(props) => colors[props.linecolor]};
    margin-top: -${(props) => props.linewidth};
  }
`;

export default Text;
