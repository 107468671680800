import React from 'react';
import { BarChart as Chart, Bar, Cell, XAxis, YAxis } from 'recharts';

import type { PeriodResult, AmountByDate } from './utils';

import ChartContainer from './ChartContainer';

import colors from 'constants/colors';
import chartColors from 'constants/chartColors';

import {
  amountsByDate,
  onClickGraph,
  CustomBarLabel,
  CustomAxisLabel,
} from './utils';

const BarChart = ({
  amounts,
  clickable,
}: {
  amounts: AmountByDate[];
  clickable?: boolean;
}) => {
  return (
    <ChartContainer clickable={clickable}>
      <Chart data={amounts} margin={{ top: 15, right: 5, bottom: 0, left: 5 }}>
        <XAxis
          dataKey="date"
          tickLine={false}
          interval={0}
          axisLine={{
            stroke: colors.lightgrey,
          }}
          tick={(props) => CustomAxisLabel({ ...props, amounts })}
        />
        <YAxis hide domain={[0, 'dataMax']} />
        <Bar
          isAnimationActive={false}
          dataKey="amount"
          barSize={15}
          label={CustomBarLabel}
          onClick={({ period }, _, event) => onClickGraph(event.target, period)}
        >
          {amounts.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                index === amounts.length - 1
                  ? chartColors.primaryDark
                  : chartColors.primary
              }
            />
          ))}
        </Bar>
      </Chart>
    </ChartContainer>
  );
};

const Simple = ({
  results,
  type = 'amount',
  clickable,
}: {
  results: PeriodResult[];
  type?: string;
  clickable?: boolean;
}) => {
  const isHidden = results.every((data) => data.result[type] === 0);

  if (isHidden) {
    return null;
  }

  return (
    <BarChart clickable={clickable} amounts={amountsByDate(results, type)} />
  );
};

BarChart.Simple = Simple;

export default BarChart;
