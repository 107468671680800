import React from 'react';
import Spinner from 'react-spinkit';

import Box from 'components/common/Box';

import colors from 'constants/colors';

const LoadingSpinner = ({
  color = 'tin',
  immediate = false,
  zoom = undefined,
  ...rest
}) => (
  <Box
    data-testid="loading-spinner"
    width="100%"
    pt="1.5rem"
    pb="1.5rem"
    textAlign="center"
    {...rest}
  >
    <Spinner
      name="line-scale"
      color={colors[color]}
      fadeIn={immediate ? 'none' : undefined}
      style={{ zoom }}
    />
  </Box>
);

export default LoadingSpinner;
