import React from 'react';
import styled, { css } from 'styled-components/macro';
import InputMask from 'react-input-mask';

import Text from 'components/common/Text';
import Box from 'components/common/Box';
import Button from 'components/advanced/Button';

import colors from 'constants/colors';

const Form = styled.form`
  text-align: center;
`;

const Label = styled.label`
  display: inline-block;
  color: ${colors.charcoal};
  margin-bottom: 8px;
`;

const Help = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: ${colors.darkgrey};
  white-space: pre-wrap;
  word-break: break-all;
`;

const Error = styled(Help)`
  color: ${colors.maroon};
`;

/**
 * @type React.FC<{ id?: string; label?: string; labelHelp?: string; children?: React.ReactNode; error?: string; help?: string; style?: any; }>
 */
export const Group = ({
  id,
  label,
  labelHelp,
  children,
  error,
  help,
  ...rest
}) => (
  <Box
    width="100%"
    maxWidth="360px"
    mb="24px"
    mx="auto"
    textAlign="left"
    {...rest}
  >
    {label && (
      <Label htmlFor={id}>
        {label}
        {labelHelp && (
          <Text
            as="span"
            className="label-help"
            fontSize="0.95rem"
            color="primary"
            ml={1}
          >
            {labelHelp}
          </Text>
        )}
      </Label>
    )}
    {React.cloneElement(children, { id, 'data-error': !!error })}
    {help && !error && <Help>{help}</Help>}
    {error && <Error>{error}</Error>}
  </Box>
);

const FADED_BG_COLOR = colors.gray200;
const NORMAL_COLOR = colors.gray500;

const inputStyle = css`
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  border: 1px solid;
  border-color: ${(props) =>
    props['data-error'] ? colors.maroon : 'rgba(0,0,0,.15)'};
  border-radius: 3px;
  background-color: ${(props) =>
    props.readOnly ? FADED_BG_COLOR : colors.white};
  font-size: 1.1rem;
  line-height: 1.618;
  appearance: none;
  ${(props) => (props.readOnly ? `color: ${NORMAL_COLOR}` : '')};

  ${(props) =>
    props.secure
      ? '-webkit-text-security: disc;'
      : ''} ::-webkit-input-placeholder {
    font-size: 1.1rem;
  }
  ::-moz-placeholder {
    font-size: 1.1rem;
  }
  :-ms-input-placeholder {
    font-size: 1.1rem;
  }
  :-moz-placeholder {
    font-size: 1.1rem;
  }

  :disabled {
    -webkit-text-fill-color: ${NORMAL_COLOR};
    opacity: 1;
    background-color: ${FADED_BG_COLOR};
    color: ${NORMAL_COLOR};
    border-color: ${(props) =>
      props['data-error'] ? colors.maroon : colors.gray300};
  }

  ::placeholder {
    color: ${colors.darkgrey};
  }
`;

export const Input = styled.input`
  ${inputStyle};
`;

export const MaskedInput = styled(({ onChange, ...rest }) => (
  <InputMask
    {...rest}
    onChange={(event) => {
      event.target.value = event.target.value.replace(/\s/g, '');
      onChange(event);
    }}
  />
))`
  ${inputStyle};
`;

export const Phone = ({ placeholder = '010 1234 5678', ...rest }) => (
  <MaskedInput
    type="tel"
    placeholder={placeholder}
    formatChars={{
      0: '0',
      1: '1',
      X: '[016789]',
      Y: '[0-9]',
    }}
    maskChar={null}
    mask="01X YYYY YYYY"
    {...rest}
  />
);

export const RegistrationNumber = (props) => (
  <MaskedInput
    type="tel"
    placeholder="예: 123 45 67890"
    maskChar={null}
    mask="999 99 99999"
    {...props}
  />
);

export const Submit = (props) => (
  <Button type="submit" mt={3} p="1rem" width="100%" {...props} />
);

Form.MaskedInput = MaskedInput;
Form.RegistrationNumber = RegistrationNumber;
Form.Input = Input;
Form.Group = Group;
Form.Phone = Phone;
Form.Submit = Submit;

export default Form;
