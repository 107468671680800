import gql from 'graphql-tag.macro';

export default gql`
  fragment businesses on BusinessConnection {
    edges {
      node {
        id
        name
        registrationNumber
        role
      }
    }
  }
`;
