import jwtDecode from 'jwt-decode';
import apolloClient from 'graphql/apolloClient';
import storage from 'local-storage-fallback';

import api from 'api';
import paths from 'paths';

const LOAD = 'auth/LOAD';
const LOAD_FAIL = 'auth/LOAD_FAIL';
const REDIRECT_AFTER_LOGIN = 'auth/REDIRECT_AFTER_LOGIN';
const REMOVE_AUTH = 'auth/REMOVE_AUTH';
const SET_ACCESS_TOKEN = 'auth/SET_ACCESS_TOKEN';

const token = storage.getItem('token');

if (!!token) {
  try {
    const { exp } = jwtDecode(token);
    if (new Date().getTime() / 1000 > exp) {
      storage.removeItem('token');
    }
  } catch (ex) {
    storage.removeItem('token');
  }
}

const initialState = {
  token: storage.getItem('token'),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        token: action.token,
      };
    case LOAD_FAIL:
      return {
        ...state,
        errors: action.errors,
      };
    case REDIRECT_AFTER_LOGIN:
      return {
        ...state,
        redirectAfterLogin: action.redirect,
      };
    case REMOVE_AUTH:
      return {
        token: null,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      };
    default:
      return state;
  }
}

export function loadAuth(token) {
  storage.setItem('token', token);

  return {
    type: LOAD,
    token,
  };
}

export function setAccessToken(accessToken) {
  return {
    type: SET_ACCESS_TOKEN,
    accessToken,
  };
}

export function removeAuth() {
  storage.removeItem('token');
  apolloClient.clearStore();
  return { type: REMOVE_AUTH };
}

export function loginSuccess(token) {
  return (dispatch, getState) => {
    const redirect = getState().auth.redirectAfterLogin;
    dispatch(setAccessToken(null));
    dispatch(loadAuth(token));

    paths.replace(redirect || paths.root());
  };
}

export function redirectAfterLogin(redirect) {
  return {
    type: REDIRECT_AFTER_LOGIN,
    redirect,
  };
}

export function refresh() {
  return (dispatch) => {
    return api.auth
      .refresh()
      .then(({ data: { jwt: token } }) => {
        dispatch(loadAuth(token));
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          dispatch(removeAuth());
          dispatch(
            redirectAfterLogin(
              `${window.location.pathname}${window.location.search}`
            )
          );
          paths.replace(paths.login.index(window.location.search));
        }
      });
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(removeAuth());
  };
}
