function isValidPhoneNumber(phoneNumber: string): boolean {
  return /^01[016789]\d{8}$/.test(phoneNumber);
}

function formatPhoneNumber(phoneNumber: string): string {
  return isValidPhoneNumber(phoneNumber)
    ? phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
    : phoneNumber;
}

export { isValidPhoneNumber, formatPhoneNumber };
