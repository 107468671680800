import React from 'react';

import ErrorLayout from 'layouts/Error';

import Box from 'components/common/Box';
import Text from 'components/common/Text';
import LogoutButton from 'pages/Auth/Logout/LogoutButton';

import logo from 'assets/shinhan-myshop/cashnote-app-icon.png';

import { withJosa } from 'utils/korean';

const ERROR_KINDS = {
  BUSINESS_NOT_FOUND: {
    target: '사업장',
    action: '등록',
  },
  CREFIA_NOT_VALID: {
    target: '카드매출',
    action: '연결',
  },
};

type ErrorKind = keyof typeof ERROR_KINDS;

const Error = ({ kind }: { kind: ErrorKind }) => {
  const { target, action } = ERROR_KINDS[kind];

  return (
    <>
      <ErrorLayout>
        <Box mt="60px" mb="48px">
          <img src={logo} alt="로고" height="73px" />
        </Box>
        <Text textAlign="left" mb="32px" fontSize="21px" fontWeight="bold">
          {target} {withJosa(action, '이가')} 필요합니다.
          <br />
          캐시노트 앱에서 {withJosa(target, '을를')} 새로 {action}해주세요.
        </Text>
        <Text textAlign="left" color="tin">
          플레이스토어 또는 앱스토어에서 캐시노트를 설치하신 다음 로그인하셔서{' '}
          {withJosa(target, '을를')} 새로 {action}해주세요.
        </Text>
      </ErrorLayout>
      <div
        style={{
          position: 'fixed',
          width: '100%',
          bottom: 0,
        }}
      >
        <LogoutButton />
      </div>
    </>
  );
};

export default Error;
